import React, { useState, useContext, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  getRedirectResult,
} from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { IoMdCloseCircle } from "react-icons/io";
import { FaUser, FaLock, FaGoogle } from "react-icons/fa";
import { auth, firestore } from "../../firebaseConfig";
import { AuthContext } from "../../contexts/AuthContext";
import AuthInput from "../Auth_Input/AuthInput";
import LoginButton from "../Login_Button/LoginButton";
import ModalOverlay from "../Modal_Overlay/ModalOverlay";
import AuthTitle from "../Auth_Title/AuthTitle";
import "./loginModal.css";

const LoginModal = ({
  isVisible,
  onClose,
  onRegisterClick,
  setShowWelcomeModal,
  setCustomerName,
  setIsGoogleSignIn,
  onResetPasswordClick,
}) => {
  const { dispatch } = useContext(AuthContext);
  const [loginInput, setLoginInput] = useState({ email: "", password: "" });

  const handleInputChange = (e) => {
    setLoginInput({ ...loginInput, [e.target.name]: e.target.value });
  };

  const updateUserInfo = async (userId, emailVerified) => {
    const userDocRef = doc(firestore, "users", userId);
    await updateDoc(userDocRef, { lastLogin: new Date(), emailVerified });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        loginInput.email,
        loginInput.password
      );
      const user = userCredential.user;

      await updateUserInfo(user.uid, user.emailVerified);
      dispatch({ type: "LOGIN_SUCCESS", payload: user });

      onClose(); // Close the login modal
    } catch (error) {
      console.error("Login failed:", error.message);
      dispatch({ type: "LOGIN_FAILURE" });
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error with Google Sign-In:", error);
    }
  };

  useEffect(() => {
    const checkGoogleSignIn = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          const user = result.user;
          const fullName = user.displayName ? user.displayName.split(" ") : [];
          const name = fullName[0] || "";
          const surname =
            fullName.length > 1 ? fullName.slice(1).join(" ") : "";

          const userDocRef = doc(firestore, "users", user.uid);
          const userDoc = await getDoc(userDocRef);

          if (!userDoc.exists()) {
            await setDoc(userDocRef, {
              name,
              surname,
              email: user.email,
              accountType: "customer",
              emailVerified: user.emailVerified,
              reviewLeft: false,
            });

            setCustomerName(name);
            setShowWelcomeModal(true);
            setIsGoogleSignIn(true);
          } else {
            await updateUserInfo(user.uid, user.emailVerified);
            onClose(); // Close the login modal for existing users
          }

          dispatch({ type: "LOGIN_SUCCESS", payload: user });
        }
      } catch (error) {
        console.error("Error with Google Sign-In:", error);
        dispatch({ type: "LOGIN_FAILURE" });
      }
    };

    checkGoogleSignIn();
  }, [
    onClose,
    setShowWelcomeModal,
    setCustomerName,
    setIsGoogleSignIn,
    dispatch,
  ]);

  if (!isVisible) return null;

  return (
    <ModalOverlay onClose={onClose}>
      <IoMdCloseCircle className="close-button" onClick={onClose} />
      <AuthTitle title="Hello!" />
      <form className="login-form" onSubmit={handleLogin}>
        <AuthInput
          label="Your email *"
          input_type="email"
          name="email"
          value={loginInput.email}
          placeholder="yourname@example.com"
          onChange={handleInputChange}>
          <FaUser />
        </AuthInput>
        <AuthInput
          label="Your password *"
          input_type="password"
          name="password"
          value={loginInput.password}
          placeholder="Password that you used while signing up"
          onChange={handleInputChange}>
          <FaLock />
        </AuthInput>
        <div className="forgot-password-container">
          <button onClick={() => onResetPasswordClick()}>
            Forgot your password?
          </button>
        </div>
        <div className="login-button-container">
          <LoginButton button_type="submit" button_text="SIGN IN" />
        </div>
        <div className="alternative-container">
          <div className="alternative-line"></div>
          <p>Alternatively</p>
          <div className="alternative-line"></div>
        </div>
        <div className="login-button-container">
          <LoginButton
            icon={<FaGoogle className="google-icon" />}
            button_text="Sign In with Google"
            additional_class="google-button"
            onClick={handleGoogleLogin}
            button_type="button"
          />
        </div>
        <div className="not-a-member-container">
          <p>Not a member yet?</p>
          <button
            type="button"
            onClick={onRegisterClick}
            className="link-style-button">
            Sign Up
          </button>
        </div>
      </form>
    </ModalOverlay>
  );
};

export default LoginModal;
