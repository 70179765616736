import React from "react";
import "./authTitle.css";

const AuthTitle = ({ title }) => {
  return (
    <div className="auth-title">
      <h3>{title}</h3>
      <div className="auth-title-line"></div>
    </div>
  );
};

export default AuthTitle;
