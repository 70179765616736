import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./confirmationModal.css";

const ConfirmationModal = ({ isVisible, onConfirm, onCancel, message }) => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setIsTransitioning(true); // Immediately start transition to visible
    } else {
      // When hiding, wait for the transition to complete before setting isTransitioning to false
      const timeoutId = setTimeout(() => {
        setIsTransitioning(false);
      }, 300); // Match the CSS transition duration
      return () => clearTimeout(timeoutId);
    }
  }, [isVisible]);

  // The modal should not be in the DOM if it's not in the transitioning state and not visible
  if (!isTransitioning && !isVisible) return null;

  // Apply the visible class only when transitioning to visible
  const modalClasses = `confirmation-modal-backdrop ${
    isTransitioning && isVisible ? "confirmation-modal-visible" : ""
  }`;

  return ReactDOM.createPortal(
    <div className={modalClasses}>
      <div className="confirmation-modal-content">
        <p>{message}</p>
        <div className="confirmation-buttons-container">
          <button onClick={onCancel} className="cancel">
            Cancel
          </button>
          <button onClick={onConfirm} className="confirm">
            Confirm
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("overlay-root")
  );
};

export default ConfirmationModal;
