import React from "react";
import "./radioButton.css";

const RadioButton = ({
  radio_title,
  radio_icon,
  name,
  onChange,
  isChecked,
}) => {
  // Split the title into words
  const titleWords = radio_title.split(" ");

  // The first word for the upper element
  const firstWord = titleWords[0];

  // Join the remaining words for the lower element
  const remainingWords = titleWords.slice(1).join(" ");

  return (
    <div className="radio-input">
      <label htmlFor={radio_title}>
        <div className="radio-title">
          <span>{firstWord}</span>
          <span>{remainingWords || "\u00A0"}</span>{" "}
          {/* Non-breaking space for empty string */}
        </div>
        {radio_icon}
      </label>
      <input
        type="radio"
        id={radio_title} // Ensuring the 'htmlFor' in label associates correctly
        name={name}
        value={radio_title}
        onChange={onChange}
        checked={isChecked} // Controlled by the isChecked prop
      />
    </div>
  );
};

export default RadioButton;
