import React, { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import "./about.css";
import Logo from "../../assets/black-logo-about.webp";
import SectionWrapper from "../Section_Wrapper/SectionWrapper";
import PageTitle from "../Page_Title/PageTitle";
import AdminButton from "../Admin_Button/AdminButton.jsx";
import { FaRegEdit } from "react-icons/fa";
import AboutEditModal from "../About_Edit_Modal/AboutEditModal.jsx";

const About = ({ isAdmin }) => {
  const [aboutContent, setAboutContent] = useState({
    content: "",
    signiture: "",
  });
  const [isAboutModalVisible, setIsAboutModalVisible] = useState(false);

  useEffect(() => {
    const docRef = doc(firestore, "about", "SRJg5nPO8u4Yw70Oc58V");
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setAboutContent({
          content: doc.data().content.trim(),
          signiture: doc.data().signiture.trim(),
        });
      } else {
        console.log("No such document!");
      }
    });

    // Cleanup function to unsubscribe from the document when the component unmounts
    return () => unsubscribe();
  }, []);

  const openAboutModal = () => setIsAboutModalVisible(true);
  const closeAboutModal = () => setIsAboutModalVisible(false);
  return (
    <SectionWrapper class_theme="about-me">
      <div className="about-container">
        <img src={Logo} alt="Logo" className="about-logo" />
        <div className="title-container">
          <PageTitle title="ABOUT ME" />
          {isAdmin && (
            <AdminButton button_text="Edit About" onClick={openAboutModal}>
              <FaRegEdit className="admin-icon" />
            </AdminButton>
          )}
        </div>
        <div className="about-text-and-picture-wrapper">
          <div className="about-text-container">
            <p>{aboutContent.content}</p>
            <p>{aboutContent.signiture}</p>
          </div>
          <div className="about-photo-wrapper">
            <div className="photo-frame"></div>
            <div className="vygintas-photo"></div>
          </div>
        </div>
      </div>
      {isAboutModalVisible && (
        <AboutEditModal
          isVisible={isAboutModalVisible}
          onClose={closeAboutModal}
          aboutId="SRJg5nPO8u4Yw70Oc58V"
          initialContent={aboutContent.content}
          initialSigniture={aboutContent.signiture}
        />
      )}
    </SectionWrapper>
  );
};

export default About;
