import React, { useEffect, useState } from "react";
import "./paymentWindow.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import SingleDetail from "../Single_Detail/SingleDetail";
import AuthTitle from "../Auth_Title/AuthTitle";
import { auth, firestore } from "../../firebaseConfig";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "@firebase/firestore";

const PaymentWindow = ({ bookingDetails, onPaymentSuccess }) => {
  const [paypalClientId, setPaypalClientId] = useState(null);
  const deposit = 100;

  useEffect(() => {
    // Fetch the client ID from your Firebase function
    fetch("https://us-central1-recovery-7ce74.cloudfunctions.net/initPayPalSDK")
      .then((response) => response.json())
      .then((data) => {
        setPaypalClientId(data.clientId); // Set the fetched client ID
      })
      .catch((error) => {
        console.error("Error fetching PayPal client ID:", error);
        // Handle any errors that occur during the fetch operation
      });
  }, []);

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          reference_id: bookingDetails.appointmentReference,
          amount: {
            currency_code: "NOK",
            value: deposit.toString(),
          },
          description: "Therapy Session Deposit Payment",
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(async (details) => {
      console.log("Payment Successful:", details);

      // Combined booking details, payment details, and user auth details
      const combinedDetails = {
        ...bookingDetails,
        paymentDetails: details,
        userId: auth.currentUser.uid,
        bookingStatus: "Scheduled",
      };

      // Generated a unique ID for the booking
      const bookingId = doc(collection(firestore, "bookings")).id;

      // Added combined details to Firestore
      await setDoc(doc(firestore, "bookings", bookingId), combinedDetails);

      // Query to find the document based on the selected date
      const q = query(
        collection(firestore, "availability"),
        where("date", "==", bookingDetails.selectedDate)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        const currentSlots = querySnapshot.docs[0].data().timeSlots;

        // Update timeslot status to 'booked'
        const updatedSlots = currentSlots.map((slot) =>
          slot.time === bookingDetails.selectedTimeslot
            ? { ...slot, status: "booked" }
            : slot
        );

        await updateDoc(docRef, { timeSlots: updatedSlots });
      }

      onPaymentSuccess(details); // This will now also close the payment window
    });
  };

  const backgroundImageStyle = {
    backgroundImage: `url(${bookingDetails.selectedService?.imageUrl || ""})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="payment-container">
      <div className="payment-box">
        <div className="booking-image" style={backgroundImageStyle}></div>
        <div className="booking-summary-container">
          <div className="booking-summary-title-container">
            <AuthTitle title="Appointment Summary" />
          </div>
          <div className="booking-details-container">
            <div className="detail-section-container">
              <div className="detail-section-title">
                <h3>Therapy Details</h3>
              </div>
              <div className="all-detail-wrapper">
                <SingleDetail
                  detail_title="Selected therapy:"
                  detail_value={bookingDetails.selectedService?.title}
                />
                <SingleDetail
                  detail_title="Selected date:"
                  detail_value={bookingDetails.selectedDate}
                />
                <SingleDetail
                  detail_title="Selected timeslot:"
                  detail_value={bookingDetails.selectedTimeslot}
                />
                <SingleDetail
                  detail_title="Therapy duration:"
                  detail_value={bookingDetails.duration}
                />
                <SingleDetail
                  detail_title="Location type:"
                  detail_value={bookingDetails.location}
                />
                <SingleDetail
                  detail_title="Location address:"
                  detail_value={bookingDetails.address}
                />
              </div>
            </div>
            <div className="detail-section-container">
              <div className="detail-section-title">
                <h3>Customer Contact Details</h3>
              </div>
              <div className="all-detail-wrapper">
                <SingleDetail
                  detail_title="Name:"
                  detail_value={bookingDetails.userName}
                />
                <SingleDetail
                  detail_title="Surname:"
                  detail_value={bookingDetails.userSurname}
                />
                <SingleDetail
                  detail_title="Email:"
                  detail_value={bookingDetails.userEmail}
                />
                <SingleDetail
                  detail_title="Phone number:"
                  detail_value={bookingDetails.userPhoneNumber}
                />
              </div>
            </div>
            <div className="detail-section-container">
              <div className="detail-section-title">
                <h3>Payment Details</h3>
              </div>
              <div className="all-detail-wrapper">
                <SingleDetail
                  detail_title="Total service fee:"
                  detail_value={`${bookingDetails.totalPrice} NOK`}
                />
                <SingleDetail
                  detail_title="Deposit to secure timeslot:"
                  detail_value={`${deposit} NOK`}
                />
                <SingleDetail
                  detail_title="Remaining fee to pay after the therapy delivery:"
                  detail_value={`${bookingDetails.totalPrice - deposit} NOK`}
                />
              </div>
            </div>
            <div className="pay-today-container">
              <div className="pay-today-line"></div>
              <h3>{`Pay ${deposit} NOK Today`}</h3>
              <div className="pay-today-line"></div>
            </div>
          </div>
          <div className="paypal-buttons-container">
            {paypalClientId && (
              <PayPalScriptProvider
                options={{ "client-id": paypalClientId, currency: "NOK" }}>
                <PayPalButtons
                  createOrder={createOrder}
                  onApprove={onApprove}
                />
              </PayPalScriptProvider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentWindow;
