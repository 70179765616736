import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./app.css";
import "./styles/calendar.css";
import MainPage from "./pages/MainPage/MainPage";
import MainMenu from "./components/MainMenu/MainMenu";
import { AuthContextProvider } from "./contexts/AuthContext";
import NewAppointment from "./pages/NewAppointment/NewAppointment";
import Appointments from "./pages/Appointments/Appointments";
import Gallery from "./pages/Gallery/Gallery";

function App() {
  return (
    <Router>
      <div className="App">
        <AuthContextProvider>
          <MainMenu />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/new-appointment" element={<NewAppointment />} />
            <Route path="/appointments" element={<Appointments />} />
            <Route path="/gallery" element={<Gallery />} />
          </Routes>
        </AuthContextProvider>
      </div>
    </Router>
  );
}

export default App;
