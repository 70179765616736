import React, { useState } from "react";
import { auth, firestore } from "../../firebaseConfig";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import "./registerModal.css";
import ModalOverlay from "../Modal_Overlay/ModalOverlay";
import { IoMdCloseCircle } from "react-icons/io";
import AuthTitle from "../Auth_Title/AuthTitle";
import RegisterInput from "../Register_Input/RegisterInput";
import LoginButton from "../Login_Button/LoginButton";
import { IoWarningOutline } from "react-icons/io5";

const RegisterModal = ({
  isVisible,
  onClose,
  onRegistrationSuccess,
  onLoginClick,
}) => {
  const [inputValues, setInputValues] = useState({
    name: "",
    surname: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });
  const [focusedField, setFocusedField] = useState("");
  const [validationStatus, setValidationStatus] = useState({
    name: null,
    surname: null,
    email: null,
    password: null,
    confirmPassword: null,
    phoneNumber: null,
  });
  const [validationMessages, setValidationMessages] = useState({
    name: "",
    surname: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });

  const validateField = (name, value) => {
    let isValid = true;
    let message = "";

    switch (name) {
      case "name":
        // Check if the name is only letters and optionally spaces, and within length limits
        isValid =
          /^[A-Za-z\s]+$/.test(value) &&
          value.length >= 2 &&
          value.length <= 20;
        message = !/^[A-Za-z\s]+$/.test(value)
          ? "Name must contain only letters."
          : value.length < 2 || value.length > 20
          ? "Name must be 2-20 letters long."
          : "";
        break;
      case "surname":
        // Assuming you want the same letter-only rule for surname
        isValid =
          /^[A-Za-z\s]+$/.test(value) &&
          value.length >= 2 &&
          value.length <= 20;
        message = !/^[A-Za-z\s]+$/.test(value)
          ? "Surname must contain only letters."
          : value.length < 2 || value.length > 20
          ? "Surname must be 2-20 letters long."
          : "";
        break;
      case "phoneNumber":
        isValid = /^\d{8}$/.test(value);
        message = isValid
          ? ""
          : "Please enter the 8-digit phone number without the +47 prefix.";
        break;
      case "email":
        // Simple email regex pattern for basic validation
        isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        message = isValid ? "" : "Please enter a valid email address.";
        break;
      case "password":
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumbers = /\d/.test(value);
        const hasSpecialChar = /[\s~`!@#$%^&*+=\-\[\]\\';,/{}|":<>?()._]/.test(
          value
        );
        const isLengthValid = value.length >= 8;
        isValid =
          hasUpperCase &&
          hasLowerCase &&
          hasNumbers &&
          hasSpecialChar &&
          isLengthValid;

        message = !isValid
          ? "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
          : "";
        break;
      case "confirmPassword":
        isValid = value === inputValues.password;
        message = isValid ? "" : "Passwords do not match.";
        break;
      default:
        break;
    }

    setValidationStatus((prevState) => ({ ...prevState, [name]: isValid }));
    setFocusedField(isValid ? "" : name); // Update focused field only if validation fails
    setValidationMessages((prevState) => ({ ...prevState, [name]: message }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);

    if (validationStatus[name]) {
      setFocusedField("");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // For the phoneNumber field, filter out non-digit characters
    if (name === "phoneNumber") {
      const filteredValue = value.replace(/\D/g, "");
      setInputValues({ ...inputValues, [name]: filteredValue });
      validateField(name, filteredValue);
    } else {
      setInputValues({ ...inputValues, [name]: value });
      validateField(name, value);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const allFieldsAreValid = Object.values(validationStatus).every(
      (status) => status === true
    );

    if (!allFieldsAreValid) {
      alert("Please correct the errors before submitting.");
      return; // Preventing the registration from proceeding
    }

    const { name, surname, email, password, confirmPassword, phoneNumber } =
      inputValues;

    // Checking if the passwords match
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      const trimmedEmail = email.trim();
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        trimmedEmail,
        password
      );
      const user = userCredential.user;

      // Send email verification
      await sendEmailVerification(user);

      // Update user's profile with the name
      await updateProfile(user, { displayName: name });

      // Save additional user info in Firestore
      const userDocRef = doc(firestore, "users", user.uid);
      await setDoc(userDocRef, {
        name,
        surname,
        phoneNumber,
        email: trimmedEmail,
        accountType: "customer",
        emailVerified: user.emailVerified,
      });

      // Reset input fields
      setInputValues({
        name: "",
        surname: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
      });

      // Call onRegistrationSuccess (show WelcomeModal)
      onRegistrationSuccess(name);
    } catch (error) {
      console.error("Registration error:", error.message);
    }
  };

  if (!isVisible) return null;

  return (
    <ModalOverlay>
      <IoMdCloseCircle className="close-button" onClick={onClose} />
      <AuthTitle title="Sign Up!" />
      <form className="register-form" onSubmit={handleRegister}>
        {validationMessages[focusedField] && (
          <div className="type-error-container">
            <IoWarningOutline className="type-error-icon" />
            <p>{validationMessages[focusedField]}</p>
          </div>
        )}
        <div className="name-surname-container">
          <div className="input">
            <RegisterInput
              label="Your Name *"
              type="text"
              name="name"
              value={inputValues.name}
              placeholder="Name"
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={validationStatus.name}
              minLength={2}
              maxLength={20}
              required
            />
          </div>
          <div className="input">
            <RegisterInput
              label="Your Surname *"
              type="text"
              name="surname"
              value={inputValues.surname}
              placeholder="Surname"
              onChange={handleChange}
              isValid={validationStatus.surname}
              minLength={2}
              maxLength={20}
              required
            />
          </div>
        </div>
        <div className="phone-input-container">
          <span className="phone-input-prefix">+47</span>
          <RegisterInput
            label="Your Phone Number *"
            type="tel"
            name="phoneNumber"
            value={inputValues.phoneNumber}
            placeholder="XX XX XX XX"
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={validationStatus.phoneNumber}
            pattern="\d{8}"
            maxLength={8}
            required
          />
        </div>
        <RegisterInput
          label="Your Email *"
          type="email"
          name="email"
          value={inputValues.email}
          placeholder="yourname@example.com"
          onChange={handleChange}
          isValid={validationStatus.email}
          required
        />
        <RegisterInput
          label="Your Password *"
          type="password"
          name="password"
          value={inputValues.password}
          placeholder="Password"
          onChange={handleChange}
          isValid={validationStatus.password}
          required
        />
        <RegisterInput
          label="Repeat Your Password *"
          type="password"
          name="confirmPassword"
          value={inputValues.confirmPassword}
          placeholder="Repeat Password"
          onChange={handleChange}
          isValid={validationStatus.confirmPassword}
          required
        />
        <div className="policy-checkbox-container">
          <input type="checkbox" id="policyCheckbox" required />
          <label htmlFor="policyCheckbox">
            I agree with the Recovery <span>Privacy</span> &{" "}
            <span>Cancellation</span> policy.
          </label>
        </div>
        <div className="submit-button-container">
          <LoginButton button_type="submit" button_text="SUBMIT" />
        </div>
      </form>
      <div className="sign-in-container">
        <p>Already a member?</p>
        <a onClick={onLoginClick}>Sign In</a>
      </div>
    </ModalOverlay>
  );
};

export default RegisterModal;
