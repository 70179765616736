import React from "react";
import "./adminButton.css";

const AdminButton = ({ onClick, children, button_text }) => {
  return (
    <div className="admin-button-container" onClick={onClick}>
      {children}
      <h4>{button_text}</h4>
    </div>
  );
};

export default AdminButton;
