import React, { useState } from "react";
import "./resetPasswordModal.css";
import { IoMdCloseCircle } from "react-icons/io";
import AuthTitle from "../Auth_Title/AuthTitle";
import ModalOverlay from "../Modal_Overlay/ModalOverlay";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import AuthInput from "../Auth_Input/AuthInput";
import { FaUser } from "react-icons/fa";
import LoginButton from "../Login_Button/LoginButton";

const ResetPasswordModal = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);

      // Clear the email input and show a success message
      setEmail("");
      setSuccessMessage("Password reset email sent. Check your inbox.");
    } catch (error) {
      console.error("Password reset failed:", error.message);
      // Handle the error, e.g., display an error message to the user
    }
  };

  return (
    <ModalOverlay onClose={onClose}>
      <IoMdCloseCircle className="close-button" onClick={onClose} />
      <AuthTitle title="Reset Password" />

      {successMessage ? (
        <div className="success-message">
          <p>{successMessage}</p>
        </div>
      ) : (
        <form className="reset-password-form" onSubmit={handleResetPassword}>
          <div className="reset-password-description">
            <p>Enter your email address to reset your password.</p>
          </div>
          <div className="email-input-container">
            <AuthInput
              label="Your email *"
              input_type="email"
              name="email"
              value={email}
              placeholder="yourname@example.com"
              onChange={(e) => setEmail(e.target.value)}>
              <FaUser />
            </AuthInput>
          </div>
          <div className="reset-submit-button-container">
            <LoginButton
              button_type="submit"
              button_text="Reset Password"
              onClick={handleResetPassword} // Add the click handler for reset
            />
          </div>
        </form>
      )}
    </ModalOverlay>
  );
};

export default ResetPasswordModal;
