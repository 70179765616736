import React from "react";
import "./appointmentConfirmation.css";
import logoSrc from "../../assets/white-logo.png";
import AuthTitle from "../Auth_Title/AuthTitle";
import { useNavigate } from "react-router-dom";

const AppointmentConfirmation = ({ bookingDetails, paymentDetails }) => {
  const navigate = useNavigate();

  const navigateToAppointments = () => {
    navigate("/appointments");
  };

  const formatDateAndTime = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleString();
  };

  const amount =
    paymentDetails &&
    paymentDetails.purchase_units &&
    paymentDetails.purchase_units[0].amount.value
      ? parseInt(paymentDetails.purchase_units[0].amount.value, 10)
      : "N/A";

  const formattedUpdateTime =
    paymentDetails && paymentDetails.update_time
      ? formatDateAndTime(paymentDetails.update_time)
      : "N/A";

  return (
    <div className="confirmation-container">
      <div className="confirmation-box">
        <div className="confirmation-logo-container">
          <img src={logoSrc} alt="Logo" className="confirmation-logo" />
        </div>
        <div className="confirmation-title-container">
          <AuthTitle title="See you soon!" />
        </div>
        <div className="customer-message-container">
          <p>Dear {bookingDetails.userName},</p>
          <p>
            I am pleased to confirm your appointment for a massage therapy
            session at Recovery. Thank you for choosing me to assist in your
            journey towards relaxation and wellness.
          </p>
        </div>
        <div className="appointment-section-title">
          <h3>Appointment Details</h3>
        </div>
        <div className="appointment-details-wrapper">
          <div className="appointment-details-labels">
            <p>Therapy name:</p>
            <p>Appointment Reference:</p>
            <p>Date:</p>
            <p>Time:</p>
            <p>Therapy session duration:</p>
            <p>Address:</p>
            <p>Therapist:</p>
          </div>
          <div className="appointment-details-values">
            <p>{bookingDetails.selectedService.title}</p>
            <p>{bookingDetails.appointmentReference}</p>
            <p>{bookingDetails.selectedDate}</p>
            <p>{bookingDetails.selectedTimeslot}</p>
            <p>{bookingDetails.duration}</p>
            <p>{bookingDetails.address}</p>
            <p>Vygintas Jurginas</p>
          </div>
        </div>
        <div className="appointment-section-title">
          <h3>Payment Details</h3>
        </div>
        <div className="appointment-details-wrapper">
          <div className="appointment-details-labels">
            <p>Payer's name:</p>
            <p>Payment Reference:</p>
            <p>Payment made on:</p>
            <p>Payment Description:</p>
            <p>Payment Value:</p>
            <p>Left to pay after the therapy:</p>
          </div>
          <div className="appointment-details-values">
            <p>
              {paymentDetails.payer.name.given_name}{" "}
              {paymentDetails.payer.name.surname}
            </p>
            <p>{paymentDetails.id}</p>
            <p>{formattedUpdateTime}</p>
            <p>{paymentDetails.purchase_units[0].description}</p>
            <p>
              {paymentDetails.purchase_units[0].amount.value}{" "}
              {paymentDetails.purchase_units[0].amount.currency_code}
            </p>
            <p>
              {bookingDetails.totalPrice}
              {" - "}
              {amount}
              {" = "}
              {bookingDetails.totalPrice - amount}{" "}
              {paymentDetails.purchase_units[0].amount.currency_code}
            </p>
          </div>
        </div>
        <div className="finishing-message-container">
          <p>
            Please arrive 10 minutes early to allow for a relaxed start.
            Remember to wear comfortable clothing and consider any personal
            preferences you might have for your session.
          </p>
          <p>Looking forward to welcoming you!</p>
          <p>Warm Regards,</p>
          <div className="therapist-details">
            <p>Vygintas Jurginas</p>
            <p>Recovery</p>
            <p>+47 99 99 99 99</p>
          </div>
          <div className="appointments-page-button-container">
            <button
              className="appointment-button"
              onClick={navigateToAppointments}>
              To Appointments
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentConfirmation;
