import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import "./mainMenu.css";
import { ReactComponent as MenuLogo } from "../../assets/White-logo-no-text.svg";
import { CiLogin } from "react-icons/ci";
import { scrollToSection } from "../../utils/scrollToSection";
import LoginModal from "../Login_Modal/LoginModal";
import RegisterModal from "../Register_Modal/RegisterModal";
import WelcomeModal from "../Welcome_Modal/WelcomeModal";
import ProfileModal from "../Profile_Modal/ProfileModal";
import ResetPasswordModal from "../Reset_Password_Modal/ResetPasswordModal";
import Avatar from "../../assets/avatar.webp";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { SlMenu } from "react-icons/sl";

const useScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
};

const MainMenu = () => {
  useScrollToTop();

  const [showMenu, setShowMenu] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [customerName, setCustomerName] = useState("");
  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const mobileMenuRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(AuthContext); // Use AuthContext

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;
    const isScrolledDown =
      prevScrollPos < currentScrollPos && currentScrollPos > 50;

    // Check if any modals are currently visible
    const isAnyModalOpen =
      showLogin || showRegister || showWelcomeModal || showProfileModal;

    // Only hide the menu if no modals are open
    if (!isAnyModalOpen) {
      setShowMenu(!isScrolledDown);
    }

    setPrevScrollPos(currentScrollPos);
  }, [
    prevScrollPos,
    showLogin,
    showRegister,
    showWelcomeModal,
    showProfileModal,
  ]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    // Check if there is a state to scroll to a specific section
    if (location.state?.scrollToSection) {
      scrollToSection(location.state.scrollToSection);
    }
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target)
      ) {
        setIsMobileMenuVisible(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onLogoClick = () => {
    if (location.pathname === "/") {
      scrollToSection("hero");
    } else {
      navigate("/");
    }
  };

  const navigateAndScroll = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollToSection: sectionId } });
    } else {
      scrollToSection(sectionId);
    }
  };

  const openLogin = () => {
    setShowLogin(true);
    setShowRegister(false);
  };

  const openRegister = () => {
    setShowRegister(true);
    setShowLogin(false);
  };

  const openResetPasswordModal = () => {
    setShowLogin(false);
    setShowResetPasswordModal(true);
  };

  const closeResetPasswordModal = () => {
    setShowResetPasswordModal(false);
    setShowLogin(true);
  };

  const closeAll = () => {
    setShowLogin(false);
    setShowRegister(false);
    setShowWelcomeModal(false);
    setShowProfileModal(false);
  };

  const handleRegistrationSuccess = (userName) => {
    setCustomerName(userName); // Store the customer's name
    setShowRegister(false); // Close the RegisterModal
    setShowWelcomeModal(true); // Show the WelcomeModal
  };

  const handleProfileClick = () => {
    setShowProfileModal(true);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuVisible((prevState) => !prevState);
  };

  return (
    <>
      <menu className={`main-menu-container ${showMenu ? "" : "hide-menu"}`}>
        <div className="menu-logo-container">
          <MenuLogo className="menu-logo" onClick={onLogoClick} />
        </div>
        <ul>
          <li onClick={() => navigateAndScroll("about")}>About</li>
          <li onClick={() => navigateAndScroll("services")}>Services</li>
          <li onClick={() => navigateAndScroll("contact")}>Contact</li>
          <li onClick={() => navigate("/gallery")}>Gallery</li>
          <li onClick={() => navigate("/appointments")}>Appointments</li>
        </ul>
        <div className="login-menu-container">
          {!currentUser ? (
            <div className="sign-in-menu-container" onClick={openLogin}>
              <h4>SIGN IN</h4>
              <CiLogin className="login-icon" />
            </div>
          ) : (
            <div
              className="user-profile-container"
              onClick={handleProfileClick}>
              <h4>{currentUser.displayName?.split(" ")[0] || "User"}</h4>{" "}
              <img
                src={currentUser.photoURL || Avatar}
                alt="User"
                className="avatar"
              />
            </div>
          )}
          <SlMenu className="menu-icon" onClick={toggleMobileMenu} />
        </div>
      </menu>
      <div
        className={`mobile-menu-container ${
          isMobileMenuVisible ? "visible" : ""
        }`}
        ref={mobileMenuRef}>
        <ul>
          <li
            onClick={() => {
              navigateAndScroll("about");
              toggleMobileMenu();
            }}>
            About
          </li>
          <li
            onClick={() => {
              navigateAndScroll("services");
              toggleMobileMenu();
            }}>
            Services
          </li>
          <li
            onClick={() => {
              navigateAndScroll("contact");
              toggleMobileMenu();
            }}>
            Contact
          </li>
          <li
            onClick={() => {
              navigate("/gallery");
              toggleMobileMenu();
            }}>
            Gallery
          </li>
          <li
            onClick={() => {
              navigate("/appointments");
              toggleMobileMenu();
            }}>
            Appointments
          </li>
        </ul>
      </div>
      <LoginModal
        isVisible={showLogin}
        onClose={closeAll}
        onRegisterClick={openRegister}
        setShowWelcomeModal={setShowWelcomeModal}
        setCustomerName={setCustomerName}
        setIsGoogleSignIn={setIsGoogleSignIn}
        onResetPasswordClick={openResetPasswordModal}
      />
      <RegisterModal
        isVisible={showRegister}
        onClose={closeAll}
        onRegistrationSuccess={handleRegistrationSuccess}
        onLoginClick={openLogin}
      />
      {showWelcomeModal && (
        <WelcomeModal
          isVisible={showWelcomeModal}
          onClose={closeAll}
          customerName={customerName}
          isGoogleSignIn={isGoogleSignIn}
        />
      )}
      {showResetPasswordModal && (
        <ResetPasswordModal onClose={closeResetPasswordModal} />
      )}
      {showProfileModal && <ProfileModal onClose={closeAll} />}
    </>
  );
};

export default MainMenu;
