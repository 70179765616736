import React, { useEffect, useState } from "react";
import "./newAppointment.css";
import Footer from "../../components/Footer/Footer";
import PageTitle from "../../components/Page_Title/PageTitle";
import OtherPageWrapper from "../../components/Other_Page_Wrapper/OtherPageWrapper";
import { useSearchParams } from "react-router-dom";
import { collection, doc, getDoc, getDocs } from "@firebase/firestore";
import { firestore } from "../../firebaseConfig";
import SelectedService from "../../components/Selected_Service/SelectedService";
import AppointmentForm from "../../components/Appointment_Form/AppointmentForm";
import PaymentWindow from "../../components/Payment_Window/PaymentWindow";
import AppointmentConfirmation from "../../components/Appointment_Confirmation/AppointmentConfirmation";
import { CSSTransition } from "react-transition-group";

const NewAppointment = () => {
  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get("serviceId");
  const [selectedService, setSelectedService] = useState(null);
  const [services, setServices] = useState([]);
  const [showAppointmentForm, setShowAppointmentForm] = useState(false);
  const [showPaymentWindow, setShowPaymentWindow] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [customFooterStyle, setCustomFooterStyle] = useState({});
  const [bookingDetails, setBookingDetails] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);

  const updateFooterStyles = () => {
    if (window.innerWidth <= 1200) {
      // Styles for mobile devices
      setCustomFooterStyle({
        position: "static",
        clipPath: "none",
      });
    } else {
      // Styles for tablets and desktops
      setCustomFooterStyle({
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        top: "auto",
        clipPath: "polygon(0 34%, 100% 0, 100% 100%, 0% 100%)",
      });
    }
  };

  useEffect(() => {
    updateFooterStyles();
    window.addEventListener("resize", updateFooterStyles); // Adjust on window resize

    return () => {
      window.removeEventListener("resize", updateFooterStyles); // Clean up
    };
  }, []);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "services"));
        const servicesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setServices(servicesData);

        // Automatically select the first service if no serviceId is provided
        if (!serviceId && servicesData.length > 0) {
          setSelectedService(servicesData[0]);
        }
      } catch (error) {
        console.error("Error fetching services: ", error);
      }
    };

    fetchServices();
  }, [serviceId]);

  useEffect(() => {
    const fetchServiceDetails = async () => {
      if (serviceId) {
        try {
          const serviceDocRef = doc(firestore, "services", serviceId);
          const serviceDoc = await getDoc(serviceDocRef);

          if (serviceDoc.exists()) {
            setSelectedService({ id: serviceDoc.id, ...serviceDoc.data() });
          } else {
            console.log("No such service!");
          }
        } catch (error) {
          console.error("Error fetching service: ", error);
        }
      }
    };

    fetchServiceDetails();
  }, [serviceId]);

  const handleServiceChange = async (event) => {
    const selectedId = event.target.value;
    const selected = services.find((service) => service.id === selectedId);
    setSelectedService(selected || null);
  };

  const handleNextClick = () => {
    setShowAppointmentForm(true); // Transition to the appointment form
  };

  const handleNextInAppointmentForm = (details) => {
    setBookingDetails(details);
    setShowAppointmentForm(false);
    setShowPaymentWindow(true);
  };

  const handlePaymentSuccess = (details) => {
    setPaymentDetails(details);
    setShowPaymentWindow(false);
    setShowConfirmation(true);
  };

  useEffect(() => {
    console.log("Payment Details Updated:", paymentDetails);
  }, [paymentDetails]);

  return (
    <div className="new-appointment-page">
      <OtherPageWrapper>
        <div className="new-appointment-title-container">
          <PageTitle title="NEW APPOINTMENT" title_class_theme="contact" />
        </div>

        <CSSTransition
          in={!showAppointmentForm && !showPaymentWindow && !showConfirmation}
          timeout={300}
          classNames="fade"
          unmountOnExit>
          <SelectedService
            selectedService={selectedService}
            services={services}
            handleServiceChange={handleServiceChange}
            handleNextClick={handleNextClick}
          />
        </CSSTransition>

        <CSSTransition
          in={showAppointmentForm}
          timeout={300}
          classNames="fade"
          unmountOnExit>
          <AppointmentForm
            selectedService={selectedService}
            onNextClick={handleNextInAppointmentForm}
          />
        </CSSTransition>

        <CSSTransition
          in={showPaymentWindow}
          timeout={300}
          classNames="fade"
          unmountOnExit>
          <PaymentWindow
            bookingDetails={bookingDetails}
            onPaymentSuccess={handlePaymentSuccess}
          />
        </CSSTransition>

        <CSSTransition
          in={showConfirmation}
          timeout={300}
          classNames="fade"
          unmountOnExit>
          <AppointmentConfirmation
            bookingDetails={bookingDetails}
            paymentDetails={paymentDetails}
          />
        </CSSTransition>
      </OtherPageWrapper>
      <Footer customStyle={customFooterStyle} />
    </div>
  );
};

export default NewAppointment;
