import React, { useState, useEffect } from "react";
import { getDocs, collection, deleteDoc, doc } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import "./services.css";
import PageTitle from "../Page_Title/PageTitle";
import SectionWrapper from "../Section_Wrapper/SectionWrapper";
import ServiceCard from "../Service_Card/ServiceCard";
import ConfirmationModal from "../Confirmation_Modal/ConfirmationModal";
import { IoIosAddCircleOutline } from "react-icons/io";
import NewMassageOverlay from "../New_Massage_Overlay/NewMassageOverlay";
import ServiceExplore from "../Service_Explore/ServiceExplore";
import AdminButton from "../Admin_Button/AdminButton";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Services = ({ isAdmin }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [isExploreVisible, setIsExploreVisible] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);

  const toggleOverlay = () => setShowOverlay(true);
  const closeOverlay = () => setShowOverlay(false);

  const handleExploreClick = (service) => {
    setSelectedService(service);
    setIsExploreVisible(true);
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "services"));
        const servicesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setServices(servicesData);
      } catch (error) {
        console.error("Error fetching services: ", error);
      }
    };

    fetchServices();
  }, []);

  const handleDeleteClick = (service) => {
    setServiceToDelete(service);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!serviceToDelete) return;

    try {
      // Proceed with deletion logic, e.g., delete from Firestore
      await deleteDoc(doc(firestore, "services", serviceToDelete.id));
      console.log(
        `Service with ID: ${serviceToDelete.id} deleted successfully.`
      );

      const updatedServices = services.filter(
        (service) => service.id !== serviceToDelete.id
      );
      setServices(updatedServices); // Update the services list in state

      setShowConfirmationModal(false);
    } catch (error) {
      console.error("Error deleting service: ", error);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmationModal(false);
    setServiceToDelete(null);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <SectionWrapper class_theme="services">
      <div className="services-title-wrapper">
        <PageTitle title="SERVICES" title_class_theme="contact" />
        {isAdmin && (
          <AdminButton onClick={toggleOverlay} button_text="New Massage">
            <IoIosAddCircleOutline className="admin-icon" />
          </AdminButton>
        )}
      </div>
      <Slider {...settings} className="slider-container">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            question={service.question}
            title={service.title}
            imageUrl={service.imageUrl}
            onExplore={() => handleExploreClick(service)}
            onDelete={() => handleDeleteClick(service)}
            isAdmin={isAdmin}
          />
        ))}
      </Slider>
      <NewMassageOverlay isVisible={showOverlay} onClose={closeOverlay} />
      {isExploreVisible && (
        <ServiceExplore
          service={selectedService}
          isVisible={isExploreVisible}
          onClose={() => setIsExploreVisible(false)}
        />
      )}
      <ConfirmationModal
        isVisible={showConfirmationModal}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        message={`Are you sure you want to delete the "${serviceToDelete?.title}" from the services?`}
      />
    </SectionWrapper>
  );
};

export default Services;
