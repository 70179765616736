import React, { useState } from "react";
import "./singleAppointment.css";
import { FcCancel } from "react-icons/fc";
import { IoChevronDown } from "react-icons/io5";
import Avatar from "../../assets/avatar.webp";

const SingleAppointment = ({ booking, userRole }) => {
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);

  const serviceImage = booking.selectedService.imageUrl || "";
  const userPhotoUrl = booking.userPhotoUrl || "";

  let imageSrc = Avatar; // Default to Avatar
  if (userRole === "admin" && userPhotoUrl) {
    imageSrc = userPhotoUrl; // User's image for admin
  } else if (userRole === "customer" && serviceImage) {
    imageSrc = serviceImage; // Use the service image for customers
  } else if (userPhotoUrl) {
    imageSrc = userPhotoUrl; // User's image from the booking
  }

  const depositAmount = parseFloat(
    booking.paymentDetails.purchase_units[0].amount.value
  );
  const totalPrice = parseFloat(booking.totalPrice);
  const remainingFee = totalPrice - depositAmount;

  const getMonthAbbreviation = (monthNumber) => {
    const monthMap = {
      0: "JAN",
      1: "FEB",
      2: "MAR",
      3: "APR",
      4: "MAY",
      5: "JUN",
      6: "JUL",
      7: "AUG",
      8: "SEP",
      9: "OCT",
      10: "NOV",
      11: "DEC",
    };
    return monthMap[monthNumber];
  };

  const rawDateTime = booking.paymentDetails.update_time;
  const date = new Date(rawDateTime);
  const formattedDateTime = date.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const parseDate = (dateString) => {
    const parts = dateString.split("/");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };

  // Parsing the selected date and update time
  const selectedDate = parseDate(booking.selectedDate);
  const updateDate = new Date(booking.paymentDetails.update_time);

  const monthAbbreviation = getMonthAbbreviation(selectedDate.getMonth());
  const day = selectedDate.getDate();
  const updateMonthAbbreviation = getMonthAbbreviation(updateDate.getMonth());
  const updateDay = updateDate.getDate();
  const updateTime = updateDate.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const toggleDetails = () => {
    setIsDetailsExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div className={`single-booking ${isDetailsExpanded ? "expanded" : ""}`}>
      <div className="main-booking-content">
        <div
          className="image-container"
          style={{ backgroundImage: `url(${imageSrc})` }}></div>
        <div className="booking-title-container">
          <p>{booking.bookingStatus}</p>
          <p>{booking.selectedService.title}</p>
          <p>Appointment Reference: {booking.appointmentReference}</p>
        </div>
        <div className="booking-cards-container">
          <div className="booking-cards-wrapper">
            <div className="booking-card">
              <label>Date</label>
              <div className="booking-date">
                <h3>{monthAbbreviation}</h3>
                <h2>{day}</h2>
              </div>
            </div>
            <div className="booking-card">
              <label>Time</label>
              <div className="booking-time">
                <h2>{booking.selectedTimeslot}</h2>
              </div>
            </div>
            <div className="booking-card">
              <label>Duration</label>
              <div className="booking-duration">
                <h2>{booking.duration}</h2>
              </div>
            </div>
            <div className="booking-card">
              <label>Address</label>
              <div className="booking-address">
                <p>{booking.address}</p>
              </div>
            </div>
            <div className="booking-card">
              <label>Scheduled on</label>
              <div className="booking-schedule">
                <div className="scheduled-date">
                  <h3>{updateMonthAbbreviation}</h3>
                  <h2>{updateDay}</h2>
                </div>
                <div className="scheduled-time">
                  <h2>{updateTime}</h2>
                </div>
              </div>
            </div>
            <div className="booking-card">
              <label>Cancel</label>
              <div className="booking-cancel">
                <FcCancel className="cancel-icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="booking-footer-container">
          <button className="more-details-button" onClick={toggleDetails}>
            <IoChevronDown className="chevron-icon" /> <p>More Details</p>{" "}
            <IoChevronDown className="chevron-icon" />
          </button>
        </div>
      </div>
      {isDetailsExpanded && (
        <div className="booking-details">
          <div className="left-side-details">
            <div className="booking-detail-section-title-container">
              <h3>Customer Contacts</h3>
            </div>
            <div className="customer-contacts-container">
              <div className="booking-detail-titles">
                <label>Full name:</label>
                <label>Email:</label>
                <label>Phone:</label>
              </div>
              <div className="booking-detail-values">
                <p>
                  {booking.userName} {booking.userSurname}
                </p>
                <p>{booking.userEmail}</p>
                <p>{booking.userPhoneNumber}</p>
              </div>
            </div>
            <div className="booking-detail-section-title-container">
              <h3>Payer Details</h3>
            </div>
            <div className="payer-details-container">
              <div className="booking-detail-titles">
                <label>Full name:</label>
                <label>Email:</label>
              </div>
              <div className="booking-detail-values">
                <p>
                  {booking.paymentDetails.payer.name.given_name}{" "}
                  {booking.paymentDetails.payer.name.surname}
                </p>
                <p>{booking.paymentDetails.payer.email_address}</p>
              </div>
            </div>
          </div>
          <div className="right-side-details">
            <div className="booking-detail-section-title-container">
              <h3>Payment Details</h3>
            </div>
            <div className="payment-details-container">
              <div className="booking-detail-titles">
                <label>Payment Reference:</label>
                <label>Payment value:</label>
                <label>Payment made on:</label>
              </div>
              <div className="booking-detail-values">
                <p>{booking.paymentDetails.id}</p>
                <p>
                  {booking.paymentDetails.purchase_units[0].amount.value}{" "}
                  {
                    booking.paymentDetails.purchase_units[0].amount
                      .currency_code
                  }
                </p>
                <p>{formattedDateTime}</p>
              </div>
            </div>
            <div className="booking-detail-section-title-container">
              <h3>Remaining Fee</h3>
            </div>
            <div className="payment-details-container">
              <div className="booking-detail-titles">
                <label>Left to pay (TOTAL):</label>
              </div>
              <div className="booking-detail-values">
                <p>
                  {remainingFee.toFixed(2)}{" "}
                  {
                    booking.paymentDetails.purchase_units[0].amount
                      .currency_code
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleAppointment;
