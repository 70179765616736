const AuthReducers = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        currentUser: null,
        isLoading: true,
        error: false,
      };
    case "LOGIN_SUCCESS":
      return {
        currentUser: action.payload,
        isLoading: false,
        error: false,
      };
    case "LOGIN_FAILURE":
      return {
        currentUser: null,
        isLoading: false,
        error: true,
      };
    case "LOGOUT":
      return {
        currentUser: null,
        isLoading: false,
        error: false,
      };
    case "UPDATE_USER_DETAILS":
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducers;
