import React, { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import "./hero.css";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button.jsx";
import { FaRegEdit } from "react-icons/fa";
import AdminButton from "../Admin_Button/AdminButton.jsx";
import HeroEditModal from "../Hero_Edit_Modal/HeroEditModal.jsx";

const Hero = ({ isAdmin }) => {
  const [heroContent, setHeroContent] = useState({ title: "", content: "" });
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const docRef = doc(firestore, "hero", "EdhRyNRl0LX6qneNFmjK");
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setHeroContent({
          title: doc.data().title.trim(),
          content: doc.data().content.trim(),
        });
      } else {
        console.log("No such document!");
      }
    });

    // Cleanup function to unsubscribe from the document when the component unmounts
    return () => unsubscribe();
  }, []);

  const openEditModal = () => setIsEditModalVisible(true);
  const closeEditModal = () => setIsEditModalVisible(false);

  return (
    <section className="hero">
      <div className="hero-title-container">
        {isAdmin && (
          <AdminButton button_text="Edit Hero" onClick={openEditModal}>
            <FaRegEdit className="admin-icon" />
          </AdminButton>
        )}
        <div className="hero-overlay-wrapper">
          <div className="hero-title">
            <h1>{heroContent.title}</h1>
          </div>
          <div className="hero-line"></div>
          <div className="hero-text">
            <p>{heroContent.content}</p>
          </div>
          <div className="hero-button">
            <Button
              button_text="BOOK NOW"
              onClick={() => navigate("new-appointment")}
            />
          </div>
        </div>
      </div>
      {isEditModalVisible && (
        <HeroEditModal
          isVisible={isEditModalVisible}
          onClose={closeEditModal}
          heroId="EdhRyNRl0LX6qneNFmjK"
          initialTitle={heroContent.title}
          initialContent={heroContent.content}
        />
      )}
    </section>
  );
};

export default Hero;
