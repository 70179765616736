import React, { useEffect, useState } from "react";
import "./videoFormModal.css";
import { TfiYoutube } from "react-icons/tfi";
import AuthTitle from "../Auth_Title/AuthTitle";
import { IoMdCloseCircle } from "react-icons/io";

const VideoFormModal = ({ onClose, onSubmit }) => {
  const [videoData, setVideoData] = useState({
    id: "",
    title: "",
    description: "",
  });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleChange = (e) => {
    setVideoData({ ...videoData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(videoData);
    handleClose();
  };

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 500);
  };

  return (
    <div className={`video-overlay ${isVisible ? "slide-in" : "slide-out"}`}>
      <div className="modal-image-container">
        <TfiYoutube className="youtube-icon-background" />
        <IoMdCloseCircle className="close-button" onClick={handleClose} />
      </div>
      <form onSubmit={handleSubmit}>
        <AuthTitle title="New Video" />
        <label>Enter video ID:</label>
        <input
          name="id"
          placeholder="Enter your video id..."
          value={videoData.id}
          onChange={handleChange}
        />
        <label>Enter video title:</label>
        <input
          name="title"
          placeholder="Enter your video title..."
          value={videoData.title}
          onChange={handleChange}
        />
        <label>Enter video description:</label>
        <textarea
          name="description"
          placeholder="Enter your video description..."
          value={videoData.description}
          onChange={handleChange}
        />
        <div className="video-buttons-container">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default VideoFormModal;
