import React from "react";
import "./loginButton.css";

const LoginButton = ({
  button_type,
  icon,
  button_text,
  additional_class,
  onClick,
}) => {
  return (
    <button
      className={`login-button ${additional_class}`}
      type={button_type}
      onClick={onClick}>
      {icon}
      {button_text}
    </button>
  );
};

export default LoginButton;
