import React, { useCallback, useEffect, useState } from "react";
import "./appointmentForm.css";
import ReactCalendar from "react-calendar";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "@firebase/firestore";
import { auth, firestore } from "../../firebaseConfig";
import { ReactComponent as TheraphyStudio } from "../../assets/spa-solid.svg";
import RadioButton from "../Radio_Button/RadioButton";
import { MdHome } from "react-icons/md";

const AppointmentForm = ({ selectedService, onNextClick }) => {
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableTimeslots, setAvailableTimeslots] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState("30 Minutes");
  const [location, setLocation] = useState("Therapy Studio");
  const [address, setAddress] = useState("99-99 address street, Bryne, NG-123");
  const [totalPrice, setTotalPrice] = useState(300);
  const [bookingDetails, setBookingDetails] = useState({});
  const [fullyBookedDates, setFullyBookedDates] = useState([]);

  const fetchAvailableDates = useCallback(async () => {
    try {
      const snapshot = await getDocs(collection(firestore, "availability"));
      const dates = [];
      const fullyBooked = [];

      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        const allBooked = data.timeSlots.every(
          (slot) => slot.status === "booked"
        );
        dates.push(data.date);

        if (allBooked) {
          fullyBooked.push(data.date);
        }
      });

      setAvailableDates(dates);
      setFullyBookedDates(fullyBooked);
    } catch (error) {
      console.error("Error fetching available dates: ", error);
    }
  }, []);

  const fetchAvailableTimeslots = useCallback(async () => {
    if (!selectedDate) return;

    const formattedDate = selectedDate.toLocaleDateString("en-GB");
    const q = query(
      collection(firestore, "availability"),
      where("date", "==", formattedDate)
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      setAvailableTimeslots([]);
      return;
    }

    const timeslots = querySnapshot.docs[0]
      .data()
      .timeSlots.filter((slot) => slot.status === "available");

    setAvailableTimeslots(timeslots);
    if (timeslots.length > 0) {
      const firstAvailableTimeslot = timeslots[0].time;
      setBookingDetails((prevDetails) => ({
        ...prevDetails,
        selectedDate: formattedDate,
        selectedTimeslot: firstAvailableTimeslot,
      }));
    }
  }, [selectedDate]);

  useEffect(() => {
    fetchAvailableDates();
  }, [fetchAvailableDates]);

  useEffect(() => {
    if (selectedDate) {
      fetchAvailableTimeslots();
    }
  }, [selectedDate, fetchAvailableTimeslots]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setBookingDetails({ ...bookingDetails, selectedDate: date });
  };

  const handleDurationChange = (event) => {
    setSelectedDuration(event.target.value);
    updatePrice(event.target.value, location);
  };

  const handleTimeslotChange = (event) => {
    const selectedTimeslot = event.target.value;
    setBookingDetails({ ...bookingDetails, selectedTimeslot });
  };

  const handleLocationChange = (event) => {
    const selectedLocation = event.target.value;

    // Extracting only the word "Remote" if it's included in the selected location
    const locationValue = selectedLocation.includes("Remote")
      ? "Remote"
      : selectedLocation;

    setLocation(locationValue);

    // Updating the address and location in bookingDetails
    if (locationValue === "Remote") {
      setAddress("");
      setBookingDetails({ ...bookingDetails, location: "Remote" });
    } else if (selectedLocation === "Therapy Studio") {
      setAddress("99-99 address street, Bryne, NG-123");
      setBookingDetails({ ...bookingDetails, location: "Therapy Studio" });
    }

    updatePrice(selectedDuration, locationValue);
  };

  const generateReferenceNumber = () => {
    const randomNumber = Math.floor(100000000 + Math.random() * 900000000); // Generating 9-digit random number
    return `R${randomNumber}`;
  };

  const checkReferenceUniqueness = async (referenceNumber) => {
    const bookingsRef = collection(firestore, "bookings");
    const querySnapshot = await getDocs(
      query(bookingsRef, where("orderReferenceId", "==", referenceNumber))
    );

    return querySnapshot.empty; // Returns true if no such reference number exists
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Formating the selected date
    const formattedDate = selectedDate
      ? selectedDate.toLocaleDateString("en-GB")
      : null;

    // Checking if all required fields are selected/filled
    if (!isFormValid()) {
      alert("Please complete all required fields.");
      return;
    }

    // Checking for user authentication and email verification
    if (auth.currentUser && auth.currentUser.emailVerified) {
      try {
        // Fetch user details from Firestore
        const userRef = doc(firestore, "users", auth.currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (!userDoc.exists()) {
          console.error("User data not found in Firestore");
          alert("User data not found.");
          return;
        }

        const userData = userDoc.data();
        const userPhotoUrl = auth.currentUser.photoURL;

        let appointmentReference = await generateReferenceNumber();
        let isUnique = await checkReferenceUniqueness(appointmentReference);

        while (!isUnique) {
          appointmentReference = await generateReferenceNumber();
          isUnique = await checkReferenceUniqueness(appointmentReference);
        }

        // Preparing the current state of booking details with user data
        const currentBookingDetails = {
          selectedService,
          selectedDate: formattedDate,
          selectedTimeslot: bookingDetails.selectedTimeslot,
          duration: selectedDuration,
          location,
          address,
          totalPrice,
          appointmentReference,
          userName: userData.name, // User's name from Firestore
          userSurname: userData.surname, // User's surname from Firestore
          userEmail: userData.email, // User's email from Firestore
          userPhoneNumber: userData.phoneNumber, // User's phone number from Firestore
          userPhotoUrl,
        };

        setBookingDetails(currentBookingDetails); // Updating state
        onNextClick(currentBookingDetails);
        console.log("Booking Details:", currentBookingDetails);
      } catch (error) {
        console.error("Error fetching user data from Firestore: ", error);
        alert("Error occurred while fetching user data.");
      }
    } else {
      alert("Please log in and verify your email to make an appointment.");
    }
  };

  const isFormValid = () => {
    const isRemote = location.startsWith("Remote");
    const isAddressValid = !isRemote || (isRemote && address.trim() !== "");

    return (
      selectedDate &&
      bookingDetails.selectedTimeslot &&
      selectedDuration &&
      location &&
      isAddressValid
    );
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const formattedDate = date.toLocaleDateString("en-GB");
      if (fullyBookedDates.includes(formattedDate)) {
        return "all-booked-date"; // Apply this class for fully booked dates
      } else if (availableDates.includes(formattedDate)) {
        return "highlighted-date"; // Apply this class for dates with available slots
      }
    }
    // Other conditions as before
  };

  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      const formattedDate = date.toLocaleDateString("en-GB");
      // Disable the tile if the date is fully booked or not in the list of available dates
      const isFullyBooked = fullyBookedDates.includes(formattedDate);
      const hasNoTimeSlots = !availableDates.includes(formattedDate);
      return isFullyBooked || hasNoTimeSlots;
    }
  };

  const updatePrice = (duration, locationValue) => {
    let basePrice = 300;
    switch (duration) {
      case "50 Minutes":
        basePrice = 450;
        break;
      case "90 Minutes":
        basePrice = 750;
        break;
      default:
        basePrice = 300;
    }

    const additionalCost = locationValue === "Remote" ? 300 : 0;
    setTotalPrice(basePrice + additionalCost);
  };

  const backgroundImageStyle = {
    backgroundImage: `url(${selectedService?.imageUrl || ""})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <form onSubmit={handleSubmit} className="appointment-container">
      <div className="appointment-form-box">
        <div
          className="selected-service-image"
          style={backgroundImageStyle}></div>
        <div className="appointment-form-content-wrapper">
          <div className="appointment-form-title-container">
            <h3>Appointment Form</h3>
          </div>
          <div className="selected-appointment-name-wrapper">
            <h4>Selected Therapy:</h4>
            <h4>{selectedService?.title}</h4>
          </div>
          <div className="appointment-form-calendar">
            <div className="label-calendar-wrapper">
              <label>Select one of the available dates:</label>
              <ReactCalendar
                minDate={new Date()}
                className="react-calendar"
                tileClassName={tileClassName}
                tileDisabled={tileDisabled}
                onChange={handleDateChange}
              />
            </div>
            <div className="timeslot-duration-location-container">
              <label>Select available timeslot:</label>
              <select onChange={handleTimeslotChange}>
                {availableTimeslots.map((timeslot, index) => (
                  <option key={index} value={timeslot.time}>
                    {timeslot.time}
                  </option>
                ))}
              </select>
              <label>Select therapy duration:</label>
              <select onChange={handleDurationChange}>
                <option value="30 Minutes">30 Minutes</option>
                <option value="50 Minutes">50 Minutes</option>
                <option value="90 Minutes">90 Minutes</option>
              </select>
              <label>Select location:</label>
              <div className="radio-selection-container">
                <RadioButton
                  radio_title="Therapy Studio"
                  name="location"
                  radio_icon={<TheraphyStudio className="radio-icon" />}
                  onChange={handleLocationChange}
                  isChecked={location === "Therapy Studio"}
                />
                <RadioButton
                  radio_title="Remote ( +300 NOK )"
                  name="location"
                  radio_icon={<MdHome className="radio-icon" />}
                  onChange={handleLocationChange}
                />
              </div>
            </div>
          </div>
          <div className="address-container">
            <label>Address:</label>
            <textarea
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Your entered address must be in Bryne, please enter..."
              disabled={
                address !== "" && location === "Therapy Studio"
              }></textarea>
          </div>
          <div className="price-container">
            <p>Therapy Total Price:</p>
            <div className="price-divider"></div>
            <p>{totalPrice} NOK</p>
          </div>
          <div className="explanation-container">
            <p>
              First deposit payment is for securing the timeslot, remaining
              amount will be taken after the therapy delivery.
            </p>
          </div>
          <div className="next-button-container">
            <button type="submit" className="appointment-button">
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AppointmentForm;
