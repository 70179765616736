import React from "react";
import "./singleReview.css";
import { GoStar, GoStarFill } from "react-icons/go";
import Avatar from "../../assets/avatar.webp";

const SingleReview = ({ name, surname, message, rating, imageUrl }) => {
  const stars = Array.from({ length: 5 }, (_, index) => index < rating);
  const imageSrc = imageUrl || Avatar;

  return (
    <div className="single-review-box">
      <div className="review-name-container">
        <h3>{name}</h3>
        <h3>{surname}</h3>
      </div>
      <div className="user-photo-container">
        <div
          className="user-photo"
          style={{ backgroundImage: `url(${imageSrc})` }}></div>
      </div>
      <div className="stars-container">
        {stars.map((isFilled, index) =>
          isFilled ? (
            <GoStarFill key={index} className="star-icon" />
          ) : (
            <GoStar key={index} className="star-icon" />
          )
        )}
      </div>
      <div className="review-text-container">
        <p>{`❝ ${message} ❞`}</p>
      </div>
    </div>
  );
};

export default SingleReview;
