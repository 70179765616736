import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, firestore } from "../../firebaseConfig";
// Import components
import Hero from "../../components/Hero/Hero";
import About from "../../components/About/About";
import Services from "../../components/Services/Services";
import Contact from "../../components/Contact/Contact";
import Testimonials from "../../components/Testimonials/Testimonials";
import Footer from "../../components/Footer/Footer";
import "./mainPage.css";

const MainPage = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const userDoc = doc(firestore, "users", currentUser.uid);
          const docSnap = await getDoc(userDoc);
          if (docSnap.exists() && docSnap.data().accountType === "admin") {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="main-page-wrapper">
      <div id="hero">
        <Hero isAdmin={isAdmin} />
      </div>
      <div id="about">
        <About isAdmin={isAdmin} />
      </div>
      <div id="services">
        <Services isAdmin={isAdmin} />
      </div>
      <div id="contact">
        <Contact isAdmin={isAdmin} />
      </div>
      <Testimonials isAdmin={isAdmin} />
      <Footer isAdmin={isAdmin} />
    </div>
  );
};

export default MainPage;
