import React from "react";
import "./authInput.css";

const AuthInput = ({
  label,
  input_type,
  name,
  value,
  placeholder,
  onChange,
  children,
}) => {
  return (
    <div className="auth-input-container">
      <label>{label}</label>
      <div className="input-icon-wrapper">
        <input
          className="auth-input"
          type={input_type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          required
        />
        <span className="icon">{children}</span>
      </div>
    </div>
  );
};

export default AuthInput;
