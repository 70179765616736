import React, { useEffect, useState } from "react";
import "./profileDetail.css";

const ProfileDetail = ({ detail_title, detail_content, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(detail_content);

  useEffect(() => {
    // Update the editedValue when detail_content changes
    setEditedValue(detail_content);
  }, [detail_content]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    if (onEdit) {
      await onEdit(editedValue); // Using onEdit here
    }
    setIsEditing(false);
  };

  const isEmailField = detail_title === "Your email address";

  return (
    <div className="profile-detail-container">
      <div className="profile-detail">
        <h4>{detail_title}</h4>
        <div className="detail-edit-container">
          {isEditing ? (
            <input
              type="text"
              value={editedValue}
              onChange={(e) => setEditedValue(e.target.value)}
              className="edit-input"
            />
          ) : (
            <h3>{detail_content}</h3>
          )}
          {isEditing ? (
            <button className="save-button" onClick={handleSaveClick}>
              Save
            </button>
          ) : // Only show the Edit button if it's not the email field
          isEmailField ? null : (
            <button className="edit-button" onClick={handleEditClick}>
              Edit
            </button>
          )}
        </div>
      </div>
      <div className="detail-line"></div>
    </div>
  );
};

export default ProfileDetail;
