import React, { useEffect, useState } from "react";
import { auth, firestore } from "../../firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import "./testimonials.css";
import PageTitle from "../Page_Title/PageTitle";
import SectionWrapper from "../Section_Wrapper/SectionWrapper";
import SingleReview from "../Single_Review/SingleReview";
import Button from "../Button/Button.jsx";
import ReviewModal from "../Review_Modal/ReviewModal.jsx";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
  const [reviews, setReviews] = useState([]);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "reviews"));
        const reviewsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setReviews(reviewsData);
      } catch (error) {
        console.error("Error fetching reviews: ", error);
      }
    };

    fetchReviews();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsUserAuthenticated(user?.emailVerified ?? false);
    });

    return () => unsubscribe();
  }, []);

  const toggleModal = () => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  };

  const sliderSettings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    focusOnHover: false,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          pauseOnHover: false,
          focusOnHover: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          pauseOnHover: false,
          focusOnHover: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          pauseOnHover: false,
          focusOnHover: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          pauseOnHover: false,
          focusOnHover: false,
        },
      },
    ],
  };

  return (
    <SectionWrapper class_theme="testimonials">
      <div className="services-title-wrapper">
        <PageTitle title="TESTIMONIALS" title_class_theme="contact" />
      </div>
      <Slider {...sliderSettings} className="testimonials-container">
        {reviews.map((review, index) => (
          <SingleReview
            key={index}
            name={review.name}
            surname={review.surname}
            message={review.message}
            rating={review.rating}
            imageUrl={review.imageUrl}
          />
        ))}
      </Slider>
      {isUserAuthenticated && (
        <div className="review-button-container">
          <Button
            button_type="button"
            button_text="LEAVE A REVIEW"
            onClick={toggleModal}
          />
        </div>
      )}
      <ReviewModal isVisible={isModalOpen} onClose={toggleModal} />
    </SectionWrapper>
  );
};

export default Testimonials;
