import React from "react";
import "./registerInput.css";
import { FaRegCircleCheck } from "react-icons/fa6";

const RegisterInput = ({
  label,
  type,
  placeholder,
  value,
  name,
  onChange,
  onBlur,
  isValid,
  ...rest
}) => {
  // Update to handle null as an initial, neutral state
  const inputClass =
    isValid === true
      ? "register-input input-valid"
      : isValid === false
      ? "register-input input-invalid"
      : "register-input"; // Neutral default class for unvalidated state

  return (
    <div className="register-input-container">
      <label>{label}</label>
      <input
        type={type}
        className={inputClass}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        required
        {...rest}
      />
      {isValid === true && <FaRegCircleCheck className="check-icon" />}
    </div>
  );
};

export default RegisterInput;
