import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { auth, firestore } from "../../firebaseConfig";
import { collection, addDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import "./reviewModal.css";
import { IoMdCloseCircle } from "react-icons/io";
import AuthTitle from "../Auth_Title/AuthTitle";
import { GoStar, GoStarFill } from "react-icons/go";
import LoginButton from "../Login_Button/LoginButton";

const ReviewModal = ({ isVisible, onClose }) => {
  const [shouldRender, setShouldRender] = useState(isVisible);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [reviewText, setReviewText] = useState("");

  useEffect(() => {
    if (isVisible) {
      setShouldRender(true); // Add the modal to the DOM
      setTimeout(() => setIsTransitioning(true), 10); // Start fade-in after a brief delay
    } else {
      setIsTransitioning(false); // Start fade-out
      const timer = setTimeout(() => setShouldRender(false), 500); // Remove from DOM after fade-out
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  const modalClass = `review-modal ${isTransitioning ? "fadeIn" : "fadeOut"}`;

  const handleRating = (newRating) => {
    setRating(newRating);
    setHoverRating(newRating);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const user = auth.currentUser;
    if (!user || !user.emailVerified) {
      alert("Only authenticated and email-verified users can leave a review.");
      return;
    }

    const userData = await getDoc(doc(firestore, "users", user.uid));
    const reviewData = {
      userId: user.uid,
      imageUrl: user.photoURL || "",
      message: reviewText,
      rating: rating,
      name: userData.exists()
        ? userData.data().name || "Anonymous"
        : "Anonymous",
      surname: userData.exists() ? userData.data().surname || "" : "",
    };

    await addDoc(collection(firestore, "reviews"), reviewData);
    await updateDoc(doc(firestore, "users", user.uid), { reviewLeft: true });

    setRating(0);
    setHoverRating(0);
    setReviewText("");
    onClose();
  };

  if (!shouldRender) return null;

  return ReactDOM.createPortal(
    <div className={modalClass}>
      <div className="review-title-container">
        <IoMdCloseCircle className="close-button" onClick={onClose} />
        <AuthTitle title="Share your experience" />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="review-rating-container">
          <h3>How would you rate Recovery?</h3>
          <div className="review-stars-container">
            {[1, 2, 3, 4, 5].map((star) => (
              <div
                key={star}
                className="star"
                onMouseEnter={() => setHoverRating(star)}
                onMouseLeave={() => setHoverRating(rating)}
                onClick={() => handleRating(star)}>
                {star <= hoverRating ? (
                  <GoStarFill className="star-icon" />
                ) : (
                  <GoStar className="star-icon" />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="review-message-container">
          <label>Review</label>
          <textarea
            maxLength={230}
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
          />
        </div>
        <div className="review-checkbox-container">
          <input type="checkbox" id="policyCheckbox" required />
          <label htmlFor="policyCheckbox">
            I accept the Recovery <span>Privacy Policy</span>.
          </label>
        </div>
        <div className="review-button-wrapper">
          <LoginButton button_type="submit" button_text="SUBMIT" />
        </div>
      </form>
    </div>,
    document.getElementById("overlay-root")
  );
};

export default ReviewModal;
