import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./newMassageOverlay.css";
import { ReactComponent as Logo } from "../../assets/white-logo-no-background.svg";
import AuthTitle from "../Auth_Title/AuthTitle";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth, firestore } from "../../firebaseConfig";
import BackButton from "../Back_Button/BackButton";

const NewMassageOverlay = ({ isVisible, onClose }) => {
  const [shouldRender, setShouldRender] = useState(isVisible);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isServiceAdded, setIsServiceAdded] = useState(false);

  const handleBackButtonClick = () => {
    setIsTransitioning(false);
    setTimeout(() => {
      onClose(); // This will set showOverlay to false in the Services component
    }, 500); // Match this timeout with your CSS transition
  };

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (isVisible) {
      setShouldRender(true);
      // Initiate opening transition
      requestAnimationFrame(() => setIsTransitioning(true));
    } else {
      // Initiate closing transition and then unmount
      setIsTransitioning(false);
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 500); // Adjust timeout to match your CSS transition duration
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  useEffect(() => {
    if (shouldRender) {
      // Start the transition after the component is mounted in the portal
      requestAnimationFrame(() => setIsTransitioning(true));
    } else {
      setIsTransitioning(false);
    }
  }, [shouldRender]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const userDoc = doc(firestore, "users", currentUser.uid);
          const docSnap = await getDoc(userDoc);
          if (docSnap.exists() && docSnap.data().accountType === "admin") {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if the user is an admin
    if (!isAdmin) {
      alert("Only admins can add services.");
      return;
    }

    // Check if a file is selected
    if (!selectedFile) {
      alert("Please select an image.");
      return;
    }

    // Gather text form data
    const formData = {
      title: event.target.elements.title.value,
      question: event.target.elements.question.value,
      description: event.target.elements.description.value,
      // Add other text fields as needed
    };

    // Upload the file to Firebase Storage
    const storage = getStorage();
    const storageRef = ref(storage, `images/${selectedFile.name}`);

    try {
      const snapshot = await uploadBytes(storageRef, selectedFile);
      const imageUrl = await getDownloadURL(snapshot.ref);
      formData.imageUrl = imageUrl;

      // Save formData to Firestore
      const docRef = await addDoc(collection(firestore, "services"), formData);
      console.log("Document written with ID: ", docRef.id);
      setIsServiceAdded(true);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  if (!shouldRender) return null;

  return ReactDOM.createPortal(
    <div
      className={`new-massage-overlay ${
        isTransitioning ? "new-massage-overlay-visible" : ""
      }`}>
      <div className="add-massage-content-container">
        <div className="left-container">
          <Logo className="add-massage-logo" />
          <div className="back-button-container">
            <BackButton onClick={handleBackButtonClick} />
          </div>
        </div>
        <div className="right-container">
          <AuthTitle title="New Massage" />
          {isServiceAdded ? (
            // Display success message when service is added
            <div className="success-message">
              <p>Service was successfully added!</p>
            </div>
          ) : (
            // Display the form when service is not yet added
            <form
              className="new-massage-inputs-container"
              onSubmit={handleSubmit}>
              <div className="single-service-detail-input">
                <label htmlFor="imageUpload">Upload an Image:</label>
                <input
                  id="imageUpload"
                  className="new-massage-input"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  required
                />
              </div>
              <div className="single-service-detail-input">
                <label htmlFor="question">Enter massage title question:</label>
                <input
                  id="question"
                  name="question"
                  className="new-massage-input"
                  type="text"
                  placeholder="E.g., What service's target is?"
                  required
                />
              </div>
              <div className="single-service-detail-input">
                <label htmlFor="title">Enter massage title:</label>
                <input
                  id="title"
                  name="title"
                  className="new-massage-input"
                  type="text"
                  placeholder="E.g., Service title"
                  required
                />
              </div>

              <div className="single-service-detail-input">
                <label htmlFor="description">Enter massage description:</label>
                <textarea
                  id="description"
                  name="description"
                  className="new-massage-text-area"
                  placeholder="Provide a description of the service..."
                  required></textarea>
              </div>
              <div className="add-service-button-container">
                <button type="submit" className="massage-button">
                  Confirm Addition
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>,
    document.getElementById("overlay-root")
  );
};

export default NewMassageOverlay;
