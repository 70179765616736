import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { firestore } from "../../firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";
import "./heroEditModal.css";
import AuthTitle from "../Auth_Title/AuthTitle";
import LoginButton from "../Login_Button/LoginButton";

const HeroEditModal = ({
  isVisible,
  onClose,
  heroId,
  initialTitle,
  initialContent,
}) => {
  const [shouldRender, setShouldRender] = useState(isVisible);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    setTitle(initialTitle.replace(/\s+/g, " ").trim());
    setContent(initialContent.replace(/\s+/g, " ").trim());
  }, [initialTitle, initialContent, isVisible]);

  useEffect(() => {
    if (isVisible) {
      setShouldRender(true);
      setTimeout(() => setIsTransitioning(true), 10);
    } else {
      setIsTransitioning(false);
      const timer = setTimeout(() => setShouldRender(false), 500);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  const modalClass = `hero-edit-modal ${
    isTransitioning ? "fadeIn" : "fadeOut"
  }`;

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Assuming heroId is the document ID for your hero content
    const heroDocRef = doc(firestore, "hero", heroId);
    await updateDoc(heroDocRef, {
      title: title,
      content: content,
    });

    onClose(); // Close the modal after saving
  };

  if (!shouldRender) return null;

  return ReactDOM.createPortal(
    <div className={modalClass}>
      <div className="hero-modal-header">
        <AuthTitle title="Edit Hero" />
      </div>
      <form className="hero-edit-form" onSubmit={handleSubmit}>
        <div className="hero-input-group">
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="hero-input-group">
          <label>Content</label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
        </div>
        <div className="hero-modal-footer">
          <LoginButton
            additional_class="cancel"
            button_text="Cancel"
            onClick={onClose}
          />
          <LoginButton
            additional_class="save"
            button_type="submit"
            button_text="Save"
          />
        </div>
      </form>
    </div>,
    document.getElementById("overlay-root")
  );
};

export default HeroEditModal;
