import React from "react";
import "./welcomeModal.css";
import { IoMdCloseCircle } from "react-icons/io";
import ModalOverlay from "../Modal_Overlay/ModalOverlay";
import AuthTitle from "../Auth_Title/AuthTitle";

function WelcomeModal({ isVisible, onClose, customerName, isGoogleSignIn }) {
  if (!isVisible) return null;
  return (
    <ModalOverlay onClose={onClose}>
      <IoMdCloseCircle className="close-button" onClick={onClose} />
      <AuthTitle title="Welcome!" />
      <div className="welcome-body">
        <h4>Dear {customerName},</h4>
        <p>
          I am delighted to welcome you to Recovery! Thank you for registering
          with us. You’ve taken the first step towards a new and exciting
          experience.
        </p>
        {!isGoogleSignIn && (
          <>
            <p>
              We've just sent a <span>verification link</span> to the email
              address you provided during registration to complete the
              registration process.
            </p>
            <p>
              Once you've verified your email, you'll gain full access to our
              services, and you'll be able to enjoy all the benefits we have to
              offer.
            </p>
          </>
        )}
        <p>Best regards,</p>
        <div className="verification-replyer-container">
          <p>Vygintas Jurginas</p>
          <p>Recovery</p>
          <p>+44 99 99 99 99</p>
        </div>
      </div>
    </ModalOverlay>
  );
}

export default WelcomeModal;
