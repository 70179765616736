import React from "react";
import "./footer.css";
import SectionWrapper from "../Section_Wrapper/SectionWrapper";
import { ReactComponent as FooterLogo } from "../../assets/footer-logo.svg";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";

const Footer = ({ customStyle }) => {
  const currentYear = new Date().getFullYear();
  return (
    <SectionWrapper class_theme="footer" customStyle={customStyle}>
      <div className="footer-container">
        <div className="find-me-container">
          <h3>Find me on</h3>
          <div className="footer-title-line"></div>
          <div className="social-icons-container">
            <FaInstagram className="social-icon" />
            <FaFacebookSquare className="social-icon" />
          </div>
        </div>
        <div className="location-contact-container">
          <div className="location-wrapper">
            <h3>Location</h3>
            <div className="footer-title-line"></div>
            <h4>Bryne</h4>
            <p>99-99 Address Street, NG-123</p>
            <h4>Other</h4>
            <ul>
              <li>
                <a href="#">Certificates</a>
              </li>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">Cancellation Policy</a>
              </li>
            </ul>
          </div>
          <div className="contact-wrapper">
            <h3>Contact</h3>
            <div className="footer-title-line"></div>
            <h4>Call Today</h4>
            <p>+47 XX XX XX XX</p>
            <h4>Email</h4>
            <p>vygintas@vjrecovery.com</p>
          </div>
        </div>
        <div className="recovery-logo-container">
          <FooterLogo className="footer-logo" />
        </div>
      </div>
      <div className="copyright-container">
        <p>
          © {currentYear} Recovery By Vygintas Jurginas. All rights reserved.
        </p>
      </div>
    </SectionWrapper>
  );
};

export default Footer;
