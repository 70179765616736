import React from "react";
import "./selectedService.css";
import AuthTitle from "../../components/Auth_Title/AuthTitle";

const SelectedService = ({
  selectedService,
  services,
  handleServiceChange,
  handleNextClick,
}) => {
  const backgroundImageStyle = {
    backgroundImage: `url(${selectedService?.imageUrl || ""})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div className="selected-appointment-container">
      <div className="selected-appointment-box">
        <div
          className="selected-appointment-left-container"
          style={backgroundImageStyle}></div>
        <div className="selected-appointment-right-container">
          <div className="selected-service-top-box">
            <h4>Selected therapy:</h4>
            <h4>{selectedService?.title}</h4>
          </div>
          <div className="appointment-selection-wrapper">
            <h4>Changed your mind?</h4>
            <label>Select one of the other available therapies:</label>
            <select value={selectedService?.id} onChange={handleServiceChange}>
              {services.map((service) => (
                <option key={service.id} value={service.id}>
                  {service.title}
                </option>
              ))}
            </select>
          </div>
          <div className="selected-appointment-title-wrapper">
            <AuthTitle title={selectedService?.title} />
          </div>
          <div className="selected-appointment-description-wrapper">
            <p>{selectedService?.description}</p>
          </div>
          <div className="selected-appointment-button-wrapper">
            <button className="appointment-button" onClick={handleNextClick}>
              NEXT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedService;
