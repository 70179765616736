import React, { useEffect, useState } from "react";
import "./gallery.css";
import OtherPageWrapper from "../../components/Other_Page_Wrapper/OtherPageWrapper";
import PageTitle from "../../components/Page_Title/PageTitle";
import Footer from "../../components/Footer/Footer";
import SingleVideo from "../../components/Single_Video/SingleVideo";
import ConfirmationModal from "../../components/Confirmation_Modal/ConfirmationModal";
import AdminButton from "../../components/Admin_Button/AdminButton";
import VideoFormModal from "../../components/Video_Form_Modal/VideoFormModal";
import { auth, firestore } from "../../firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { IoIosAddCircleOutline } from "react-icons/io";

const Gallery = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [showVideoForm, setShowVideoForm] = useState(false);
  const [videos, setVideos] = useState([]);
  const [customFooterStyle, setCustomFooterStyle] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [videoToDelete, setVideoToDelete] = useState(null);

  const updateFooterStyles = () => {
    if (window.innerWidth <= 1200) {
      // Styles for mobile devices
      setCustomFooterStyle({
        position: "static",
        clipPath: "none",
      });
    } else {
      // Styles for tablets and desktops
      setCustomFooterStyle({
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        top: "auto",
        clipPath: "polygon(0 34%, 100% 0, 100% 100%, 0% 100%)",
      });
    }
  };

  useEffect(() => {
    updateFooterStyles();
    window.addEventListener("resize", updateFooterStyles); // Adjust on window resize

    return () => {
      window.removeEventListener("resize", updateFooterStyles); // Clean up
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const userDoc = doc(firestore, "users", currentUser.uid);
          const docSnap = await getDoc(userDoc);
          if (docSnap.exists() && docSnap.data().accountType === "admin") {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const videosCollectionRef = collection(firestore, "videos");
        const querySnapshot = await getDocs(videosCollectionRef);

        const videosData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setVideos(videosData);
      } catch (error) {
        console.error("Error fetching videos from Firestore: ", error);
      }
    };

    fetchVideos();
  }, []);

  const handleNewVideoClick = () => {
    setShowVideoForm(true);
  };

  const closeForm = () => {
    setShowVideoForm(false);
  };

  const handleDeleteClick = (video) => {
    setVideoToDelete(video);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!videoToDelete) return;

    try {
      await deleteDoc(doc(firestore, "videos", videoToDelete.id));
      console.log(`Video with ID: ${videoToDelete.id} deleted successfully.`);

      const updatedVideos = videos.filter(
        (video) => video.id !== videoToDelete.id
      );
      setVideos(updatedVideos);
      setShowConfirmationModal(false);
    } catch (error) {
      console.error("Error deleting video: ", error);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmationModal(false);
    setVideoToDelete(null);
  };

  const handleSubmitVideoForm = async (videoData) => {
    try {
      const videosCollectionRef = collection(firestore, "videos");

      const newVideoRef = await addDoc(videosCollectionRef, {
        videoId: videoData.id,
        videoTitle: videoData.title,
        videoDescription: videoData.description,
      });

      // Update state to include new video
      const newVideo = {
        id: newVideoRef.id,
        videoId: videoData.id,
        videoTitle: videoData.title,
        videoDescription: videoData.description,
      };

      setVideos((prevVideos) => [...prevVideos, newVideo]);

      console.log("Video added successfully");
      closeForm();
    } catch (error) {
      console.error("Error adding video to Firestore: ", error);
    }
  };

  return (
    <div className="gallery-page">
      <OtherPageWrapper>
        <div className="gallery-title-container">
          <PageTitle title="GALLERY" title_class_theme="contact" />
          {isAdmin && (
            <div className="add-video-container">
              <AdminButton
                button_text="New Video"
                onClick={handleNewVideoClick}>
                <IoIosAddCircleOutline className="admin-icon" />
              </AdminButton>
            </div>
          )}
        </div>
        <div className="gallery-description-container">
          <div className="galery-description-text-container">
            <p>
              Lorem ipsum dolor sit amet consectetur. Arcu lectus turpis nisl
              tincidunt vitae. At convallis fermentum sed ac mi adipiscing. Nisl
              cursus suspendisse habitant.
            </p>
          </div>
        </div>
        <div className="gallery-videos-container">
          {videos.map((video) => (
            <SingleVideo
              key={video.id}
              videoId={video.videoId}
              title={video.videoTitle}
              description={video.videoDescription}
              isAdmin={isAdmin}
              onDelete={() => handleDeleteClick(video)}
            />
          ))}
        </div>
      </OtherPageWrapper>
      <Footer customStyle={customFooterStyle} />
      <ConfirmationModal
        isVisible={showConfirmationModal}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        message={`Are you sure you want to delete "${videoToDelete?.videoTitle}" from the gallery?`}
      />
      {showVideoForm && (
        <VideoFormModal onClose={closeForm} onSubmit={handleSubmitVideoForm} />
      )}
    </div>
  );
};

export default Gallery;
