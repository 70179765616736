import { createContext, useEffect, useReducer } from "react";
import AuthReducers from "./AuthReducer";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseConfig";

const INITIAL_STATE = {
  currentUser: null,
  isLoading: true, // Set isLoading initially to true while checking auth state
  error: false,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducers, INITIAL_STATE);

  // Check the user's authentication state when the app loads
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        dispatch({ type: "LOGIN_SUCCESS", payload: user });
      } else {
        // User is signed out.
        dispatch({ type: "LOGOUT" });
      }
    });

    // Unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser: state.currentUser,
        isLoading: state.isLoading,
        error: state.error,
        dispatch,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
