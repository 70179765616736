import React from "react";
import "./singleDetail.css";

const SingleDetail = ({ detail_title, detail_value }) => {
  return (
    <div className="single-detail-container">
      <div className="detail-title-container">
        <p>{detail_title}</p>
      </div>
      <div className="detail-value-container">
        <p>{detail_value}</p>
      </div>
    </div>
  );
};

export default SingleDetail;
