import React from "react";
import "./pageTitle.css";

const PageTitle = ({ title, title_class_theme }) => {
  return (
    <div className="page-title">
      <h1 className={title_class_theme}>{title}</h1>
      <div className="page-title-line"></div>
    </div>
  );
};

export default PageTitle;
