import React from "react";
import "./serviceCard.css";
import { ReactComponent as MenuLogo } from "../../assets/White-logo-no-text.svg";
import { MdDeleteForever } from "react-icons/md";

const ServiceCard = ({
  question,
  title,
  imageUrl,
  onExplore,
  onDelete,
  isAdmin,
}) => {
  const cardImageStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="card" style={cardImageStyle}>
      {isAdmin && (
        <MdDeleteForever
          className="delete-service"
          onClick={() => onDelete()}
        />
      )}
      <div className="card-title">
        <MenuLogo className="card-logo" />
        <h4 className="title-question">{question}</h4>
        <h3 className="title">{title}</h3>
      </div>
      <div className="card-button-container">
        <button onClick={onExplore}>Learn More</button>
      </div>
    </div>
  );
};

export default ServiceCard;
