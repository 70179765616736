export const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    const sectionTop = section.offsetTop;

    const negativeMargins = {
      hero: 0,
      about: -10,
      services: -25,
      contact: -40,
    };

    const adjustment =
      ((negativeMargins[sectionId] || 0) * window.innerHeight) / 100;

    window.scrollTo({
      top: sectionTop + adjustment,
      behavior: "smooth",
    });
  }
};
