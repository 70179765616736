import React, { useState } from "react";
import "./contact.css";
import PageTitle from "../Page_Title/PageTitle";
import SectionWrapper from "../Section_Wrapper/SectionWrapper";
import Button from "../Button/Button.jsx";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        "https://us-central1-recovery-7ce74.cloudfunctions.net/sendContactForm",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // Handle success - maybe clear the form or show a success message
        console.log("Email sent successfully");
        setFormData({ name: "", email: "", subject: "", message: "" });
      } else {
        // Handle errors
        console.error("Error sending email");
      }
    } catch (error) {
      console.error("There was an error sending the form:", error);
    }
  };

  return (
    <SectionWrapper class_theme="contact-me">
      <div className="contact-title-wrapper">
        <PageTitle title="CONTACT ME" title_class_theme="contact" />
      </div>
      <form onSubmit={handleSubmit}>
        <input
          name="name"
          className="contact-input"
          type="text"
          placeholder="Enter your name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
        <input
          name="email"
          className="contact-input"
          type="email"
          placeholder="Enter your email address"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <input
          name="subject"
          className="contact-input"
          type="text"
          placeholder="Enter your subject"
          value={formData.subject}
          onChange={handleInputChange}
          required
        />
        <textarea
          name="message"
          className="contact-input text-area"
          placeholder="Enter your enquiry"
          value={formData.message}
          onChange={handleInputChange}
          required></textarea>
        <div className="contact-button-container">
          <Button button_type="submit" button_text="SEND" />
        </div>
      </form>
    </SectionWrapper>
  );
};

export default Contact;
