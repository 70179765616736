import React, { useState, useEffect } from "react";
import "./appointments.css";
import { onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  getDocs,
  collection,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { auth, firestore } from "../../firebaseConfig";
import Footer from "../../components/Footer/Footer";
import OtherPageWrapper from "../../components/Other_Page_Wrapper/OtherPageWrapper";
import PageTitle from "../../components/Page_Title/PageTitle";
import { MdOutlineCalendarMonth } from "react-icons/md";
import AdminButton from "../../components/Admin_Button/AdminButton";
import BackButton from "../../components/Back_Button/BackButton";
import ReactCalendar from "react-calendar";
import AuthTitle from "../../components/Auth_Title/AuthTitle";
import SingleAppointment from "../../components/Single_Appointment/SingleAppointment";
import { IoAdd } from "react-icons/io5";
import { MdDeleteForever } from "react-icons/md";

const Appointments = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [viewState, setViewState] = useState("appointments");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedHour, setSelectedHour] = useState("00");
  const [selectedMinute, setSelectedMinute] = useState("00");
  const [timeSlots, setTimeSlots] = useState([]);
  const [datesWithSlots, setDatesWithSlots] = useState([]);
  const [clickedHighlightedDate, setClickedHighlightedDate] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [customFooterStyle, setCustomFooterStyle] = useState({});

  const updateFooterStyles = () => {
    if (window.innerWidth <= 1200) {
      // Styles for mobile devices
      setCustomFooterStyle({
        position: "static",
        clipPath: "none",
      });
    } else {
      // Styles for tablets and desktops
      setCustomFooterStyle({
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        top: "auto",
        clipPath: "polygon(0 34%, 100% 0, 100% 100%, 0% 100%)",
      });
    }
  };

  useEffect(() => {
    updateFooterStyles();
    window.addEventListener("resize", updateFooterStyles); // Adjust on window resize

    return () => {
      window.removeEventListener("resize", updateFooterStyles); // Clean up
    };
  }, []);

  useEffect(() => {
    fetchDatesWithSlots();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const userDoc = doc(firestore, "users", currentUser.uid);
          const docSnap = await getDoc(userDoc);
          if (docSnap.exists() && docSnap.data().accountType === "admin") {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!(selectedDate instanceof Date)) {
      setSelectedDate(new Date(selectedDate));
    }
  }, [selectedDate]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const userDoc = doc(firestore, "users", currentUser.uid);
          const docSnap = await getDoc(userDoc);

          if (docSnap.exists()) {
            const userType = docSnap.data().accountType;
            setIsAdmin(userType === "admin");

            // Fetch bookings based on user type
            if (userType === "admin") {
              // Fetch all bookings for admin
              const allBookingsSnapshot = await getDocs(
                collection(firestore, "bookings")
              );
              setBookings(
                allBookingsSnapshot.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                }))
              );
            } else {
              // Fetch only user's bookings
              const userBookingsQuery = query(
                collection(firestore, "bookings"),
                where("userId", "==", currentUser.uid)
              );
              const userBookingsSnapshot = await getDocs(userBookingsQuery);
              setBookings(
                userBookingsSnapshot.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                }))
              );
            }
          }
        } catch (error) {
          console.error("Error fetching user/bookings data: ", error);
        }
      } else {
        setIsAdmin(false);
        setBookings([]); // Clear bookings if user logs out
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filterAppointments = () => {
    return bookings.filter((booking) => {
      // Safely check if userEmail and reference are defined and include the search query
      const userEmail = booking.userEmail || "";
      const reference = booking.appointmentReference || "";
      return userEmail.includes(searchQuery) || reference.includes(searchQuery);
    });
  };

  const displayedAppointments = searchQuery ? filterAppointments() : bookings;

  const toggleCalendar = () => {
    if (viewState === "appointments") {
      setViewState("sliding");
      setTimeout(() => {
        setViewState("calendar");
      }, 300);
    }
  };

  const toggleAppointments = () => {
    if (viewState === "calendar") {
      setViewState("sliding");
      setTimeout(() => {
        setViewState("appointments");
      }, 300);
    }
  };

  const formatSelectedDate = () => {
    return selectedDate.toLocaleDateString("en-GB"); // Format as "day/month/year"
  };

  const addTimeSlot = () => {
    const formattedDate = formatSelectedDate();
    const newTime = `${selectedHour}:${selectedMinute}`;
    const newTimeSlot = {
      date: formattedDate,
      time: newTime,
      status: "available",
    };

    if (
      !timeSlots.some(
        (slot) => slot.date === newTimeSlot.date && slot.time === newTime
      )
    ) {
      setTimeSlots([...timeSlots, newTimeSlot]);
    } else {
      alert("This time slot is already added.");
    }
  };

  const removeTimeSlot = async (index) => {
    const updatedTimeSlots = timeSlots.filter((_, i) => i !== index);
    setTimeSlots(updatedTimeSlots); // Update local state

    const formattedDate = formatSelectedDate();
    await updateAvailabilityInFirestore(formattedDate, updatedTimeSlots);
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const formattedDate = date.toLocaleDateString("en-GB");

      // Find if the date is in datesWithSlots and if all timeslots are booked
      const dateInfo = datesWithSlots.find((d) => d.date === formattedDate);

      if (formattedDate === clickedHighlightedDate) {
        return "clicked-highlighted-date";
      }

      if (dateInfo) {
        if (dateInfo.allBooked) {
          return "all-booked-date"; // Apply this class if all timeslots for this date are booked
        } else {
          return "highlighted-date"; // Apply this class if any timeslot for this date is available
        }
      }

      if (selectedDate && date.toDateString() === selectedDate.toDateString()) {
        return "react-calendar__tile--custom-active";
      }
    }

    return null;
  };

  const sortTimeSlots = () => {
    return [...timeSlots].sort((a, b) => {
      // Split time into hours and minutes
      const [hoursA, minutesA] = a.time.split(":").map(Number);
      const [hoursB, minutesB] = b.time.split(":").map(Number);

      // Compare hours first, then minutes if hours are equal
      if (hoursA !== hoursB) {
        return hoursA - hoursB;
      }
      return minutesA - minutesB;
    });
  };

  const fetchDatesWithSlots = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(firestore, "availability")
      );
      const loadedDates = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const allBooked = data.timeSlots.every(
          (slot) => slot.status === "booked"
        );
        return { date: data.date, allBooked };
      });
      setDatesWithSlots(loadedDates);
    } catch (error) {
      console.error("Error fetching dates: ", error);
    }
  };

  const fetchAvailabilityForDay = async (selectedDay) => {
    try {
      const q = query(
        collection(firestore, "availability"),
        where("date", "==", selectedDay)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const availabilityData = querySnapshot.docs[0].data();
        setTimeSlots(availabilityData.timeSlots);
      } else {
        // Handle the case where there is no availability data for the selected day
        setTimeSlots([]);
      }
    } catch (error) {
      console.error("Error fetching availability: ", error);
    }
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    const formattedDate = newDate.toLocaleDateString("en-GB");
    fetchAvailabilityForDay(formattedDate);

    // Set clicked highlighted date if it has slots
    if (datesWithSlots.includes(formattedDate)) {
      setClickedHighlightedDate(formattedDate);
    } else {
      setClickedHighlightedDate(null);
    }
  };

  const confirmAndSaveTimeSlots = async () => {
    const formattedDate = formatSelectedDate();
    if (timeSlots.length > 0) {
      // Check if the date already has an associated document in Firestore
      const q = query(
        collection(firestore, "availability"),
        where("date", "==", formattedDate)
      );
      const querySnapshot = await getDocs(q);

      let docRef;
      if (!querySnapshot.empty) {
        // If document exists, get the reference to update
        const docId = querySnapshot.docs[0].id;
        docRef = doc(firestore, "availability", docId);
      } else {
        // If no document exists, create a new document reference
        docRef = doc(collection(firestore, "availability"));
      }

      // Update or set the document with the timeslots
      await setDoc(docRef, { date: formattedDate, timeSlots }, { merge: true });

      fetchDatesWithSlots(); // Refresh the dates with slots
      setTimeSlots([]); // Clear the current timeslots
    } else {
      alert("No time slots to save.");
    }
  };

  const updateAvailabilityInFirestore = async (date, updatedTimeSlots) => {
    try {
      // Find the document ID for the given date
      const q = query(
        collection(firestore, "availability"),
        where("date", "==", date)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const docId = querySnapshot.docs[0].id;
        const docRef = doc(firestore, "availability", docId);

        if (updatedTimeSlots.length === 0) {
          // If there are no time slots, delete the document
          await deleteDoc(docRef);
        } else {
          // Update the document with the new time slots array
          await updateDoc(docRef, { timeSlots: updatedTimeSlots });
        }

        console.log("Availability updated successfully");

        // Refresh dates with slots
        await fetchDatesWithSlots();
      }
    } catch (error) {
      console.error("Error updating availability: ", error);
    }
  };

  return (
    <div className="appointments-page">
      <OtherPageWrapper>
        <div className="appointments-title-container">
          <PageTitle title="APPOINTMENTS" title_class_theme="contact" />
          <div className="set-availability-container">
            {isAdmin && (
              <div className="set-availability-container">
                <AdminButton
                  button_text="Availability"
                  onClick={toggleCalendar}>
                  <MdOutlineCalendarMonth className="admin-icon" />
                </AdminButton>
              </div>
            )}
          </div>
        </div>
        <div className="appointments-container">
          {viewState !== "calendar" && (
            <div
              className={`appointments-overlay ${
                viewState === "sliding" ? "slide-out-right" : ""
              }`}>
              <div className="all-appointments-container">
                {isAdmin && ( // Render the search bar only for admin
                  <div className="search-input-container">
                    <div className="filter-label-container">
                      <label>Search Appointments</label>
                    </div>
                    <input
                      type="text"
                      placeholder="Enter customer's email or booking reference"
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                    />
                  </div>
                )}
                {/* ... [rest of your JSX code] */}
                {displayedAppointments.length > 0 ? (
                  displayedAppointments.map((booking) => (
                    <SingleAppointment
                      key={booking.id}
                      booking={booking}
                      userRole={isAdmin ? "admin" : "customer"}
                    />
                  ))
                ) : (
                  <div className="no-appointments-message-container">
                    <p>You have no appointments yet.</p>
                  </div>
                )}
              </div>
            </div>
          )}
          {viewState !== "appointments" && (
            <div
              className={`calendar-overlay ${
                viewState === "sliding" ? "start-left" : "slide-in-left"
              }`}>
              <div className="set-availability-title-container">
                <div className="back-button-container">
                  <BackButton onClick={toggleAppointments} />
                </div>
                <AuthTitle title="Availability" />
              </div>
              <div className="calendar-time-wrapper">
                <div className="calendar-container">
                  <label>Select Date:</label>
                  <div className="calendar-wrapper">
                    <ReactCalendar
                      minDate={new Date()}
                      tileClassName={tileClassName}
                      value={selectedDate}
                      onChange={handleDateChange}
                      className="react-calendar"
                    />
                  </div>
                </div>
                <div className="time-picker-container">
                  <label>Select Time</label>
                  <div className="hour-minutes-container">
                    <div className="hours-container">
                      <label>Hours</label>
                      <select
                        value={selectedHour}
                        onChange={(e) => setSelectedHour(e.target.value)}>
                        {Array.from({ length: 24 }, (_, i) =>
                          i.toString().padStart(2, "0")
                        ).map((hour) => (
                          <option key={hour} value={hour}>
                            {hour}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="minutes-container">
                      <label>Minutes</label>
                      <select
                        value={selectedMinute}
                        onChange={(e) => setSelectedMinute(e.target.value)}>
                        {["00", "15", "30", "45"].map((minute) => (
                          <option key={minute} value={minute}>
                            {minute}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="add-timeslot-button-container">
                      <button
                        className="add-timeslot-button"
                        onClick={addTimeSlot}>
                        {<IoAdd className="add-icon" />} Add
                      </button>
                    </div>
                  </div>
                  <div className="available-times-container">
                    <label>Available Times</label>
                    {timeSlots.length > 0 ? (
                      <ul>
                        {sortTimeSlots().map((slot, index) => (
                          <li
                            key={index}
                            className={
                              slot.status === "booked" ? "booked-timeslot" : ""
                            }>
                            {slot.date} - {slot.time}
                            <MdDeleteForever
                              className="delete-icon"
                              onClick={() => removeTimeSlot(index)}
                            />
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>None</p>
                    )}
                  </div>
                  {timeSlots.length > 0 && (
                    <button
                      className="confirm-button"
                      onClick={confirmAndSaveTimeSlots}>
                      Confirm
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </OtherPageWrapper>
      <Footer customStyle={customFooterStyle} />
    </div>
  );
};

export default Appointments;
