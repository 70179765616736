import React from "react";
import "./singleVideo.css";
import { MdDeleteForever } from "react-icons/md";

const SingleVideo = ({ videoId, title, description, onDelete, isAdmin }) => {
  // Directly use the video_id to construct the video URL
  const videoUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="single-video-container">
      <div className="video-container">
        <iframe
          src={videoUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder="0"
          width="100%"
          height="100%"></iframe>
      </div>
      <div className="video-title-description-container">
        {isAdmin && (
          <MdDeleteForever
            className="delete-service"
            onClick={() => onDelete()}
          />
        )}
        <div className="video-title-container">
          <h3>{title}</h3>
        </div>
        <div className="video-description-container">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default SingleVideo;
