import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./serviceExplore.css";
import AuthTitle from "../Auth_Title/AuthTitle";
import { IoMdCloseCircle } from "react-icons/io";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

const ServiceExplore = ({ service, isVisible, onClose }) => {
  const [shouldRender, setShouldRender] = useState(isVisible);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const navigate = useNavigate();

  const backgroundImageStyle = {
    backgroundImage: `url(${service ? service.imageUrl : ""})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  const handleBookNow = () => {
    navigate(`/new-appointment?serviceId=${service.id}`);
  };

  useEffect(() => {
    // Similar transition logic as in NewMassageOverlay
    if (isVisible) {
      setShouldRender(true);
      requestAnimationFrame(() => setIsTransitioning(true));
    } else {
      setIsTransitioning(false);
      const timer = setTimeout(() => setShouldRender(false), 500);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  if (!shouldRender) return null;

  return ReactDOM.createPortal(
    <div
      className={`service-explore-overlay ${
        isTransitioning ? "service-explore-overlay-visible" : ""
      }`}>
      <div
        className="left-image-container"
        style={backgroundImageStyle} // Apply the background image style
      ></div>
      <div className="right-content-container">
        <IoMdCloseCircle className="service-explore-close" onClick={onClose} />
        <div className="service-explore-title-container">
          <AuthTitle
            title={`${service.title}`}
            className="service-explore-title"
          />
        </div>
        <div className="service-description-container">
          <p>{service.description}</p>
        </div>
        <div className="service-booking-button-container">
          <Button
            button_text="BOOK NOW"
            button_type="button"
            onClick={handleBookNow}
          />
        </div>
      </div>
    </div>,
    document.getElementById("overlay-root")
  );
};

export default ServiceExplore;
