import React from "react";
import "./backButton.css";
import { IoIosArrowRoundBack } from "react-icons/io";

const BackButton = ({ onClick }) => {
  return (
    <div className="back-button" onClick={onClick}>
      <IoIosArrowRoundBack className="back-icon" />
      <p>Back</p>
    </div>
  );
};

export default BackButton;
