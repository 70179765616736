import React from "react";
import "./button.css";

const Button = ({ button_text, button_type, onClick }) => {
  return (
    <button className="btn btn1" type={button_type} onClick={onClick}>
      {button_text}
    </button>
  );
};

export default Button;
