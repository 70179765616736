import React, { useContext, useState, useEffect } from "react";
import "./profileModal.css";
import { signOut } from "firebase/auth";
import { auth, firestore } from "../../firebaseConfig";
import { AuthContext } from "../../contexts/AuthContext";
import { IoMdCloseCircle } from "react-icons/io";
import ModalOverlay from "../Modal_Overlay/ModalOverlay";
import AuthTitle from "../Auth_Title/AuthTitle";
import Avatar from "../../assets/avatar.webp";
import ProfileDetail from "../Profile_Detail/ProfileDetail";
import { doc, updateDoc, getDoc } from "@firebase/firestore";

const ProfileModal = ({ onClose }) => {
  const { currentUser, dispatch } = useContext(AuthContext);
  const [phoneNumber, setPhoneNumber] = useState(
    currentUser?.phoneNumber || ""
  );
  const [name, setName] = useState("User");
  const [surname, setSurname] = useState("Surname");

  useEffect(() => {
    // Fetch the user data from Firestore when the component mounts
    const fetchUserData = async () => {
      if (!currentUser) {
        return;
      }

      const userDocRef = doc(firestore, "users", currentUser.uid);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        setPhoneNumber(userData.phoneNumber || "");
        setName(userData.name || "User");
        setSurname(userData.surname || "Surname");
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      dispatch({ type: "LOGOUT" });
      onClose();
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };

  const updateUserDetail = async (field, value) => {
    if (!currentUser) {
      console.error("User is undefined. Cannot update user detail.");
      return;
    }

    const userDocRef = doc(firestore, "users", currentUser.uid);
    try {
      await updateDoc(userDocRef, { [field]: value });
      console.log("User detail updated");

      // Update the phoneNumber state immediately when it's changed
      if (field === "phoneNumber") {
        setPhoneNumber(value);
      }

      dispatch({
        type: "UPDATE_USER_DETAILS",
        payload: { [field]: value },
      });
    } catch (error) {
      console.error("Error updating user detail:", error);
    }
  };

  return (
    <ModalOverlay onClose={onClose}>
      <IoMdCloseCircle className="close-button" onClick={onClose} />
      <AuthTitle title="Profile" />
      <div className="profile-content-wrapper">
        <div className="profile-image-name-container">
          <div className="profile-picture-container">
            <img
              src={currentUser?.photoURL || Avatar}
              alt="User"
              className="user-picture"
            />
          </div>
          <div className="profile-name-surname-container">
            <h3>{name}</h3>
            <h3>{surname}</h3>
            <div className="email-verification-status-container">
              <h5>Email:</h5>
              <h5
                style={{
                  color: currentUser?.emailVerified ? "#71ee4b" : "#F85454",
                }}>
                {currentUser?.emailVerified ? "Verified" : "Not Verified"}
              </h5>
            </div>
          </div>
        </div>
        <div className="email-phone-password-container">
          <ProfileDetail
            detail_title="Your email address"
            detail_content={currentUser?.email || "Not available"}
          />
          <ProfileDetail
            detail_title="Your phone number"
            detail_content={phoneNumber || "Not available"}
            onEdit={(editedValue) =>
              updateUserDetail("phoneNumber", editedValue)
            }
          />
        </div>
        <div className="logout-container">
          <a onClick={handleLogout}>SIGN OUT</a>
        </div>
      </div>
    </ModalOverlay>
  );
};

export default ProfileModal;
